import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function RoundOneInterviewType({ advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        <span
          style={{ display: 'inline-block', marginRight: 5 }}
          role="img"
          aria-label="tada-emoji"
        >
          🎉
        </span>
        Congrats, you have an <EmphasizedText>interview</EmphasizedText>{' '}
        scheduled!
        <span
          style={{ display: 'inline-block', marginLeft: 5 }}
          role="img"
          aria-label="tada-emoji"
        >
          🎉
        </span>
      </StyledContent>

      <StyledContent>
        Is this a culture-fit/phone screen or a full technical interview?
      </StyledContent>

      <ButtonWrapper>
        <Button
          margin="right"
          onPress={() => advanceStage('RoundOneInterviewPhoneScreen')}
        >
          Phone Screen
        </Button>
        <Button onPress={() => advanceStage('RoundOneInterviewTechScreen')}>
          Technical Screen
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
