import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { QUERY_TRACKED_JOBS } from '../../../gql/queries'
import SwimLanes from './SwimLanes'

export default function JobTracker() {
  const { data, /* loading, */ error } = useQuery(QUERY_TRACKED_JOBS)

  function sortJobs(jobs) {
    // TODO: user prioritization? For now, order gigs by ID:
    const sortedJobs = jobs.sort((a, b) => {
      return a.id < b.id ? -1 : 1
    })
    // Display declined and rejected gigs last
    const active = sortedJobs.filter(
      job => job.stage !== 'DoneRejected' && job.stage !== 'DeclinedOffer'
    )
    const declined = sortedJobs.filter(job => job.stage === 'DeclinedOffer')
    const rejected = sortedJobs.filter(job => job.stage === 'DoneRejected')
    return active.concat(declined, rejected)
  }

  let jobs = []
  if (data && data.trackedJobs) {
    jobs = sortJobs(data.trackedJobs)
  }

  return <SwimLanes jobs={jobs} error={error} />
}
