import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function NetworkCold({ job, advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        The cold approach is tougher, but people still get jobs this way all the
        time.
      </StyledContent>

      <StyledContent>
        <i>
          Don't just throw your resume and application into a black hole,
          though!
        </i>
      </StyledContent>

      <div>
        <EmphasizedText>
          Find at least one developer at the company
        </EmphasizedText>{' '}
        via LinkedIn or Twitter. Ideally, you want someone in a similar role to
        the one you would be in. Junior, Mid-level, or Senior Developer roles
        are fine, but Principal Engineer or CTO roles are too high up in the
        chain.
      </div>

      <ButtonWrapper>
        <Button
          color="gray"
          margin="right"
          onPress={() => advanceStage('TweakResume')}
        >
          (Skip Networking)
        </Button>
        <Button onPress={() => advanceStage('NetworkAddContact')}>
          Found Someone
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
