import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../../shared/Button'

export default function AddContactForm({ cancelAdd, addContact }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [notes, setNotes] = useState('')

  return (
    <StyledContactForm>
      <StyledContactFormHeader>
        <StyledTitle>Add Contact:</StyledTitle>
        <StyledBackButton size="icon" onPress={cancelAdd}>
          <i style={{ fontSize: '32px' }} className="material-icons">
            arrow_back
          </i>
        </StyledBackButton>
      </StyledContactFormHeader>
      <StyledContactFormBody>
        <input
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Name"
          type="text"
        />
        <StyledInput
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email (Optional)"
          type="text"
        />
        <StyledTextArea
          value={notes}
          onChange={e => setNotes(e.target.value)}
          placeholder="Notes (Optional)"
          type="text"
        />
        <StyledSubmitButton
          disabled={!name}
          onPress={() => addContact({ name, email, notes })}
        >
          Add Contact
        </StyledSubmitButton>
      </StyledContactFormBody>
    </StyledContactForm>
  )
}

const StyledContactForm = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 4px #ccc;
  padding: 12px;
  border: 3px dashed #ccc;
  border-radius: 6px;
`

const StyledContactFormBody = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContactFormHeader = styled.div`
  position: relative;
  padding-bottom: 6px;
`

const StyledBackButton = styled(Button)`
  position: absolute;
  right: -10px;
  top: -15px;
  transform: scale(0.55);
`

const StyledTitle = styled.div`
  font-weight: bold;
`

const StyledInput = styled.input`
  display: block;
  margin-top: 12px;
`

const StyledTextArea = styled.textarea`
  display: block;
  margin-top: 12px;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px !important;
`
