import SelectHosting from './blog/SelectHosting'
import BrainstormContent from './blog/BrainstormContent'
import ScheduleWriting from './blog/ScheduleWriting'
import OutlinePost from './blog/OutlinePost'
import Write from './blog/Write'
import FinishingTouches from './blog/FinishingTouches'

import BrainstormTalkContent from './speak/BrainstormTalkContent'
import FindVenue from './speak/FindVenue'
import WriteTalk from './speak/WriteTalk'
import PracticeTalk from './speak/PracticeTalk'
import DeliverTalk from './speak/DeliverTalk'

import ResearchMeetups from './volunteer/ResearchMeetups'
import ResearchConferences from './volunteer/ResearchConferences'
import VolunteerReachOut from './volunteer/VolunteerReachOut'

import IdentifyOpportunities from './startcommunity/IdentifyOpportunities'
import FirstMembers from './startcommunity/FirstMembers'
import BrainstormSponsors from './startcommunity/BrainstormSponsors'
import Launch from './startcommunity/Launch'

import IdentifyInterests from './newskill/IdentifyInterests'
import ScopeProject from './newskill/ScopeProject'
import SkillBlog from './newskill/SkillBlog'

export default {
  // blog
  SelectHosting,
  BrainstormContent,
  ScheduleWriting,
  OutlinePost,
  Write,
  FinishingTouches,
  // speak
  BrainstormTalkContent,
  FindVenue,
  WriteTalk,
  PracticeTalk,
  DeliverTalk,
  // volunteer
  ResearchConferences,
  ResearchMeetups,
  VolunteerReachOut,
  // startcommunity
  IdentifyOpportunities,
  FirstMembers,
  BrainstormSponsors,
  Launch,
  // newskill
  IdentifyInterests,
  ScopeProject,
  SkillBlog,
}
