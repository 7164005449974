import React from 'react'
import styled, { css } from 'styled-components'
import AwesomeButton from './AwesomeButton/'

export default function Button({
  children,
  color = 'primary',
  disabled = false,
  floatRight = false,
  fullWidth = false,
  margin = 'none',
  onPress,
  submit = false,
  type = 'secondary',
  ...restProps
}) {
  return (
    <StyledButton
      submit={submit}
      color={color}
      disabled={disabled}
      floatRight={floatRight}
      fullWidth={fullWidth}
      margin={margin}
      onPress={onPress}
      type={type}
      {...restProps}
    >
      {children}
    </StyledButton>
  )
}

const lblue3 = '#e4eff9'
// const lblue2 = '#cadff3'
const gray3 = '#636d77'

const StyledButton = styled(AwesomeButton)`
  --button-default-height: 44px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 6px;
  --button-horizontal-padding: 20px;
  --button-raise-level: 4px;
  --button-hover-pressure: 1;
  --transform-speed: 0.175s;
  --button-primary-color: #1e88e5;
  --button-primary-color-dark: #1360a4;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: #187bd1;
  --button-primary-border: none;
  --button-secondary-color: white;
  --button-secondary-color-active: ${lblue3};
  --button-secondary-color-dark: ${gray3};
  --button-secondary-color-light: ${gray3};
  --button-secondary-color-hover: ${lblue3};
  --button-secondary-border: 2px solid ${gray3};
  --button-anchor-color: #0e4f88;
  --button-anchor-color-dark: #072743;
  --button-anchor-color-light: #ffffff;
  --button-anchor-color-hover: #0d4a7f;
  --button-anchor-border: none;
  --button-disabled-color: #ddd;
  --button-disabled-color-hover: #ddd;

  &.aws-btn--icon {
    --button-default-border-radius: 50%;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ color }) =>
    color === 'gray' &&
    css`
      --button-secondary-color: white;
      --button-secondary-color-active: #fdfdfd;
      --button-secondary-color-dark: #777;
      --button-secondary-color-light: #777;
      --button-secondary-color-hover: #eee;
      --button-secondary-border: 2px solid #777;
    `}

  ${({ color }) =>
    color === 'red' &&
    css`
      --button-secondary-color: white;
      --button-secondary-color-active: #fff0f0;
      --button-secondary-color-dark: #cc5555;
      --button-secondary-color-light: #e56060;
      --button-secondary-color-hover: #fff0f0;
      --button-secondary-border: 2px solid #e56060;
    `}

  ${({ floatRight }) =>
    floatRight &&
    css`
      float: right;
    `}

  ${({ margin }) =>
    margin === 'right' &&
    css`
      margin-right: 6px;
    `}

  ${({ margin }) =>
    margin === 'top' &&
    css`
      margin-top: 12px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      &:hover {
        cursor: not-allowed;
      }
    `}
`
