import React from 'react'
import GoalTitle from '../GoalTitle'

export default function DeliverTalk() {
  return (
    <div>
      <GoalTitle title="Deliver" icon="looks_5" />

      <p>
        When it's showtime, take a deep breath, smile, and give it your best
        shot.
      </p>

      <p>
        It's natural to have some nerves. You can win over some smiles in the
        audience by making a quick admission, e.g.,{' '}
        <i>
          "Hey everyone, I'm excited to be here, but nervous! This is my first
          time speaking at a Meetup!"
        </i>
      </p>

      <p>
        Before or after your talk is also a great time to let folks know that
        you are looking for your next career opportunity! Once you're done, you
        can officially add <i>Meetup speaker</i> to your resume.
      </p>
    </div>
  )
}
