import React from 'react'
import GoalTitle from '../GoalTitle'

export default function IdentifyOpportunities() {
  return (
    <div>
      <GoalTitle title="Identify opportunities" icon="looks_one" />

      <p>
        As a founding member of a user group, you can rapidly grow your network
        and credibility.
      </p>

      <p>
        Have you identified a need for a new community in your region? Here's a
        few ideas to consider:
      </p>

      <ul>
        <li>
          Is there an exciting new technology without a user group in your area?
        </li>
        <li>
          Are you especially interested in the combination of technology and
          another field? e.g., education, environmentalism, art, etc.
        </li>
        <li>
          Is there a specific group of technologists you want to bring together?
          e.g., the LGBTQ community, people of color, mothers, etc.
        </li>
        <li>What are your goals for members of this community?</li>
      </ul>
    </div>
  )
}
