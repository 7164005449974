import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'

export default class CoverLetter extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Write a cover letter" icon="✒️" />

        <p>
          Unless you're asked not to write one, a cover letter is always a good
          idea to include in an application. Here's a simple template:
        </p>

        <p>
          In the first paragraph, let the employer know you're excited to apply
          for this position and include how you came across the opportunity.
        </p>

        <p>
          In the next paragraph, let the company know why you are uniquely
          qualified to enhance their team.
        </p>

        <p>
          In another paragraph, let the company know why you're especially
          interested in them and their industry.
        </p>
      </div>
    )
  }
}
