import Placeholder from './Placeholder'

import Breathe from './mind/Breathe'
import Learn from './mind/Learn'
import Smile from './mind/Smile'

import Blog from './bonus/Blog'
import OpenSource from './bonus/OpenSource'
import Organize from './bonus/Organize'
import Speak from './bonus/Speak'
import Specialize from './bonus/Specialize'

import IanInterviewPrep from './practice/IanInterviewPrep'

import Moonshots from './companies/Moonshots'
import Shots from './companies/Shots'

import StarterTemplates from './resume/StarterTemplates'
import Summary from './resume/Summary'
import TailorResume from './resume/TailorResume'
import SkillList from './resume/SkillList'
import OnePage from './resume/OnePage'
import CvCompiler from './resume/CvCompiler'

import CoverLetter from './coverletter'

import SubmitApplications from './jobstages/SubmitApplications'

import Interview from './jobstages/Interview'
import Accept from './jobstages/Accept'

import ThankYous from './interview/ThankYous'

import Mentor from './giveback/Mentor'

export default {
  Accept,
  Blog,
  Breathe,
  CoverLetter,
  CvCompiler,
  IanInterviewPrep,
  Interview,
  Learn,
  Mentor,
  Moonshots,
  OnePage,
  OpenSource,
  Organize,
  Placeholder,
  Shots,
  SkillList,
  Smile,
  Speak,
  Specialize,
  StarterTemplates,
  SubmitApplications,
  Summary,
  TailorResume,
  ThankYous,
}
