import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import SubstepTitle from '../flowsteps/SubstepTitle'
import Button from '../shared/Button'
import FadeIn from './FadeIn'

export default function Breathe({ advanceStep, stepKey }) {
  const parentVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 2, delayChildren: 2, delay: 1 },
    },
    closed: {
      opacity: 0,
      transition: { staggerChildren: 2, delayChildren: 2, delay: 1 },
    },
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <SubstepTitle title="Breathe" />

      <AnimatePresence>
        <motion.div variants={parentVariants} initial="closed" animate="open">
          <FadeIn>
            First things first,
            <br />
            getting a developer job
            <br />
            can be a long, taxing journey.
          </FadeIn>

          <FadeIn>
            It may take a while and you'll likely
            <br />
            encounter some stress along the way.
          </FadeIn>

          <FadeIn>
            <i>So, take a nice deep breath.</i>
          </FadeIn>

          <FadeIn>
            <div style={{ fontSize: 38 }} role="img" aria-label="breath-emoji">
              🌬
            </div>
          </FadeIn>

          <FadeIn>
            <Button onPress={() => advanceStep(stepKey)}>Woosah</Button>
          </FadeIn>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
