import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function Retrospective({ advanceStage }) {
  const [text, setText] = useState('')

  return (
    <StyledWrapper>
      <StyledContent>
        Sorry this one didn't work out.
        <span
          style={{ display: 'inline-block', marginLeft: 3 }}
          role="img"
          aria-label="disappointed-emoji"
        >
          😔
        </span>
      </StyledContent>

      <StyledContent>
        As much as it may sting, take a moment to reflect on what you can learn
        from the experience.
        <ul>
          <li>What would you do differently?</li>
          <li>
            If you identified gaps in your knowledge, how can you address them?
          </li>
          <li>What did you do well that you want to repeat?</li>
        </ul>
      </StyledContent>

      <StyledTextArea
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder="What can you take away from this experience?"
      />

      <ButtonWrapper>
        <Button onPress={() => advanceStage('DoneRejected')}>Save</Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`

const StyledTextArea = styled.textarea`
  display: block;
  max-width: 100%;
`
