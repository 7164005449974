import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default class ListItem extends Component {
  static propTypes = {
    text: PropTypes.string,
    subtext: PropTypes.string,
  }

  render() {
    const { text, subtext } = this.props

    return (
      <StyledListItem>
        <StyledText>{text}</StyledText>
        {subtext && <StyledSubtext>{subtext}</StyledSubtext>}
      </StyledListItem>
    )
  }
}

const StyledListItem = styled.li`
  margin-bottom: 12px;
`

const StyledText = styled.div`
  font-weight: bold;
`

const StyledSubtext = styled.div`
  font-style: italic;
  font-size: 14px;
`
