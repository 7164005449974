import React from 'react'
import GoalTitle from '../GoalTitle'

export default function OutlinePost() {
  return (
    <div>
      <GoalTitle title="Outline your first post" icon="looks_4" />

      <p>
        Before you start writing, consider who is in your target audience. How
        technical are they? What is safe to assume they already know? Would a
        diagram or graphic help this reader?
      </p>

      <p>
        Start by mapping out the broad points you want to make. What do you want
        the reader to understand or be able to do by the end of this post?
      </p>

      <p>
        With this context, write some possible section titles. If you get stuck
        going in chronological order, try working your way back from the end.
      </p>
    </div>
  )
}
