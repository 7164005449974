import React from 'react'
import AnimateHeight from 'react-animate-height'
import styled, { css } from 'styled-components'
import DoneButton from './DoneButton'

import Components from './'
import Placeholder from './Placeholder'

const components = { ...Components }

export default function StepContainer({
  showTracker,
  complete,
  title,
  active,
  stepIndex,
  activeSubstep,
  setActive,
  advanceStep,
  substeps = [],
}) {
  function renderSubstepComponent(componentType) {
    const Component = components[componentType]
    if (!Component) return <Placeholder />

    // TODO: handle via apollo or redux
    if (
      [
        'Shots',
        'Moonshots',
        'SubmitApplications',
        'Interview',
        'Accept',
      ].includes(componentType)
    ) {
      return <Component showTracker={showTracker} />
    }

    return <Component />
  }

  const completedSubsteps = substeps.reduce((sum, substep) => {
    if (substep.complete) return sum + 1
    return sum
  }, 0)
  const substepProgress = `${completedSubsteps}/${substeps.length || 1}`

  return (
    <StyledContainer
      active={active}
      duration={1000}
      height={active ? 'auto' : 36}
    >
      <StyledHeader
        active={active}
        complete={complete}
        onClick={() => setActive(stepIndex, 0)}
      >
        <StyledHeaderText active={active}>{title}</StyledHeaderText>
        <StyledSubstepProgress>{substepProgress}</StyledSubstepProgress>
      </StyledHeader>

      {/* If no substeps: */}
      {!substeps.length && (
        <StyledSubstepBody>
          {complete && '√'} {title}
          <br />
          <br />
          <DoneButton advanceStep={advanceStep} />
        </StyledSubstepBody>
      )}

      {/* If substeps: */}
      {substeps.map((substep, index) => (
        <StyledContainer
          substep
          complete={substep.complete}
          key={index}
          duration={1000}
          active={activeSubstep === index}
          height={activeSubstep === index ? 'auto' : 40}
        >
          <StyledSubstepBody
            substep
            complete={substep.complete}
            active={activeSubstep === index}
            onClick={() => setActive(stepIndex, index)}
          >
            {renderSubstepComponent(substep.componentType)}
          </StyledSubstepBody>
          <DoneButton
            advanceStep={advanceStep}
            stepKey={substep.componentType}
          />
        </StyledContainer>
      ))}
    </StyledContainer>
  )
}

const activeColor = '#FFF0B8'
const completedColor = '#e1fed7'

const StyledContainer = styled(AnimateHeight)`
  box-shadow: 0 3px 4px #ccc;
  background-color: white;
  opacity: 0.5;
  margin: 18px 12px 0;
  border-radius: 6px;
  transition: opacity 0.3s;

  :last-child {
    margin-bottom: 12px;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      padding: 0 0 1px;
    `}

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        opacity: 1;
      }
    `}

  ${({ substep }) =>
    substep &&
    css`
      margin-top: 12px;
    `}

  ${({ active, substep }) =>
    active &&
    substep &&
    css`
      border: 2px solid ${activeColor};
    `}

  ${({ active, substep }) =>
    !active &&
    substep &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  ${({ active, substep, complete }) =>
    active &&
    substep &&
    complete &&
    css`
      border: 2px solid ${completedColor};
    `}

  ${({ active, substep, complete }) =>
    !active &&
    substep &&
    complete &&
    css`
      background-color: ${completedColor};
    `}
`

const StyledHeader = styled.div`
  position: relative;
  font-family: 'Dosis';
  background-color: #eee;
  padding: 8px 12px;
  color: #bbb;
  text-transform: uppercase;
  border-radius: 6px 6px 0 0;
  line-height: 1.4;

  ${({ active }) =>
    active &&
    css`
      color: #8c8365;
      background-color: ${activeColor};
    `}

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  ${({ complete }) =>
    complete &&
    css`
      color: #587453;
      background-color: ${completedColor};
    `}
`

const StyledHeaderText = styled.div`
  transition: transform 1s, margin-left 0.95s;

  ${({ active }) =>
    active &&
    css`
      transform: scale(1.15);
      margin-left: 8%;
    `}
`

const StyledSubstepBody = styled.div`
  padding: 12px;
`

const StyledSubstepProgress = styled.div`
  position: absolute;
  right: 12px;
  top: 8px;
`
