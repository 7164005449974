import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_JOB } from '../../../gql/mutations'
import Button from '../../shared/Button'

export default function NewJobForm({ isModal, closeModal }) {
  const [company, setCompany] = useState('')
  const [url, setUrl] = useState('')
  const [title, setTitle] = useState('')
  const [createJob /*, { data, loading, error }*/] = useMutation(CREATE_JOB, {
    refetchQueries: ['QUERY_TRACKED_JOBS'],
  })
  // console.log('∆∆∆ createJob data', data)
  // console.log('∆∆∆ createJob loading', loading)
  // console.log('∆∆∆ createJob error', error)

  function resetForm() {
    setCompany('')
    setUrl('')
    setTitle('')
  }

  function handleClick() {
    const job = {
      company,
      title,
      url,
      notes: '',
    }
    createJob({ variables: { input: { ...job } } })
    if (isModal) closeModal()
    resetForm()
  }

  return (
    <StyledWrapper isModal={isModal}>
      <input
        value={company}
        onChange={e => setCompany(e.target.value)}
        placeholder="Company name"
        type="text"
      />
      <StyledInput
        value={title}
        onChange={e => setTitle(e.target.value)}
        placeholder="Job title"
        type="text"
      />
      <StyledInput
        value={url}
        onChange={e => setUrl(e.target.value)}
        placeholder="(Optional) URL to job posting"
        type="text"
      />
      <StyledSubmitButton disabled={!company || !title} onPress={handleClick}>
        Track Job
      </StyledSubmitButton>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  background-color: white;
  padding: 12px;
  border-radius: 6px;
  border: 3px dashed;

  ${({ isModal }) =>
    isModal &&
    css`
      border: none;
      padding: 0;
    `}
`

const StyledInput = styled.input`
  display: block;
  margin-top: 12px;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px !important;
`
