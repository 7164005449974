import React from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { DELETE_USER_JOB } from '../../../gql/mutations'
import Button from '../../shared/Button'

export default function Settings({ jobId, resetJob, isModal, closeModal }) {
  const [
    deleteUserJob,
    { loading: deleting, error: deleteError },
  ] = useMutation(DELETE_USER_JOB, { refetchQueries: ['QUERY_TRACKED_JOBS'] })

  function handleDeleteUserJob() {
    if (deleting) return
    deleteUserJob({ variables: { input: { jobId } } })
    if (isModal) closeModal()
  }

  return (
    <StyledWrapper>
      {deleteError && (
        <StyledError>Whoops! {deleteError.toString()}</StyledError>
      )}

      <div>Reset this job to the first stage?</div>
      <StyledSubmitButton type="secondary" color="red" onPress={resetJob}>
        Reset Job
      </StyledSubmitButton>

      <div style={{ marginTop: '12px' }}>Stop tracking this job?</div>
      <StyledSubmitButton
        type="secondary"
        color="red"
        onPress={handleDeleteUserJob}
      >
        Delete Job
      </StyledSubmitButton>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px !important;
`

const StyledError = styled.div`
  color: rgb(229, 96, 96);
  margin-top: 12px;
`
