import React, { useState } from 'react'
// import { useDispatch, useSelector, useStore } from 'react-redux'
import styled from 'styled-components'
// import { useQuery, useMutation } from '@apollo/react-hooks'
// import { QUERY_STEPS } from '../gql/queries'
// import { COMPLETE_STEP } from '../../gql/mutations'
import GoalDetail from './GoalDetail'
import SubstepTitle from '../SubstepTitle'
// import StepContainer from './StepContainer'
import goalData from '../../../stepData'
// import { setActiveSteps } from '../../../store/ui/actions'

export default function Goals() {
  const [goals] = useState(goalData)
  const [activeGoalTitle, setActiveGoalTitle] = useState(null)
  const [goalContent, setGoalContent] = useState('goalList')

  // const dispatch = useDispatch()
  // const store = useStore()
  // const userId = store.getState().auth.userId
  // const { data [>, loading, error<] } = useQuery(QUERY_STEPS, {
  // variables: { userId },
  // })
  // const [completeStep] = useMutation(COMPLETE_STEP, {
  // refetchQueries: ['QUERY_STEPS'],
  // })

  // useEffect(() => {
  // if (data) {
  // updateCompletions(steps, data.completedSteps)
  // viewLatestStep()
  // }
  // }, [data])

  // function updateCompletions(steps, completedSteps) {
  // const completedCodes = completedSteps.map(cs => cs.code)
  // const newSteps = steps.slice()

  // // Mark each substep as complete
  // newSteps.forEach((step, stepIndex) => {
  // step.steps.forEach((substep, substepIndex) => {
  // if (completedCodes.includes(substep.componentType)) {
  // newSteps[stepIndex].steps[substepIndex].complete = true

  // // If all substeps are complete, mark the parent as complete
  // const substepStatuses = newSteps[stepIndex].steps.map(
  // substep => substep.complete
  // )
  // if (!substepStatuses.includes(false)) {
  // newSteps[stepIndex].complete = true
  // }
  // }
  // })
  // })

  // updateStepData(newSteps)
  // }

  // function advanceStep(stepName) {
  // // if (stepName) completeStep({ variables: { input: { code: stepName } } })

  // let newActiveSubstep = activeSubstep
  // let newActiveStep = activeStep

  // const isLastSubstep = steps[activeStep].steps.length === activeSubstep + 1

  // if (isLastSubstep) {
  // newActiveSubstep = 0
  // newActiveStep = activeStep + 1
  // } else {
  // newActiveSubstep = activeSubstep + 1
  // }

  // setActive(newActiveStep, newActiveSubstep)
  // }

  // function setActive(activeStep, activeSubstep) {
  // setActiveStep(activeStep)
  // setActiveSubstep(activeSubstep)
  // // dispatch(setActiveSteps(activeStep, activeSubstep))
  // }

  // function viewLatestStep() {
  // const stepIndex = steps.findIndex(step => !step.complete)

  // // All steps are complete, set the last step & substep as active
  // if (stepIndex === -1) {
  // const lastStepIndex = steps.length - 1
  // const lastStep = steps[lastStepIndex]
  // const lastSubstepIndex = lastStep.steps.length - 1
  // return setActive(lastStepIndex, lastSubstepIndex)
  // }

  // const substepIndex = steps[stepIndex].steps.findIndex(
  // step => !step.complete
  // )

  // setActive(stepIndex, substepIndex)
  // }

  function handleSetActiveGoal(title) {
    setActiveGoalTitle(title)
    setGoalContent('goalDetail')
  }

  const activeGoal = goals.find(g => g.title === activeGoalTitle)

  if (goalContent === 'goalDetail') {
    return (
      <GoalDetail
        goal={activeGoal}
        viewGoalList={() => setGoalContent('goalList')}
      />
    )
  }

  return (
    <GoalsPageWrapper>
      <SubstepTitle title="Goals" />
      <p>
        Invest in yourself by building your
        <br />
        brand or growing your network.
      </p>

      {goals.map(goal => (
        <StyledStepWrapper
          key={goal.title}
          onClick={() => handleSetActiveGoal(goal.title)}
        >
          <StyledStep>
            <i
              style={{ fontSize: '22px', marginRight: '8px' }}
              className="material-icons"
            >
              {goal.icon}
            </i>
            <StyledStepDetails>
              <StyledTitle>{goal.title}</StyledTitle>
            </StyledStepDetails>
          </StyledStep>
        </StyledStepWrapper>
      ))}

      {/* <StepContainerWrapper>
        {steps.map((step, index) => (
          <StepContainer
            {...step}
            key={step.title}
            setActive={setActive}
            active={activeStep === index}
            stepIndex={index}
            activeSubstep={activeSubstep}
            advanceStep={advanceStep}
            substeps={step.steps || []}
          />
        ))}
      </StepContainerWrapper>*/}
    </GoalsPageWrapper>
  )
}

const GoalsPageWrapper = styled.div`
  padding: 24px 16px 16px;
  background-color: white;
  text-align: center;
`

const StyledStepWrapper = styled.div`
  border-radius: 6px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledStep = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: #eee;
  padding: 8px 6px;
  box-shadow: 0 3px 4px #ccc;
  border-radius: 6px;
  transition: 500ms;
  text-align: left;

  &:hover {
    background: #ddd;
    cursor: pointer;
    box-shadow: none;
  }
`

const StyledStepDetails = styled.div`
  flex-grow: 1;
`

const StyledTitle = styled.div`
  font-weight: bold;
  padding-right: 6px;
`
