import React from 'react'
import styled from 'styled-components'
import Button from '../../../shared/Button'

export default function ContactList({ contacts, selectContact, viewAddForm }) {
  if (contacts.length === 0) {
    return (
      <StyledWrapper>
        <div>Add your first contact:</div>
        <br />
        <StyledButton onPress={viewAddForm}>Add Contact</StyledButton>
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper>
      {contacts.map(contact => (
        <StyledContactWrapper
          key={contact.id}
          onClick={() => selectContact(contact.id)}
        >
          <StyledContact>
            <i
              style={{ fontSize: '32px', marginRight: '6px' }}
              className="material-icons"
            >
              face
            </i>
            <StyledContactDetails>
              <StyledName>{contact.name}</StyledName>
              <StyledEmail>{contact.email}</StyledEmail>
            </StyledContactDetails>
          </StyledContact>
        </StyledContactWrapper>
      ))}

      <Button onPress={viewAddForm}>Add Contact</Button>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContactWrapper = styled.div`
  border-radius: 6px;
  margin-bottom: 12px;
`

const StyledContact = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: #eee;
  padding: 8px 6px;
  box-shadow: 0 3px 4px #ccc;
  border-radius: 6px;
  transition: 300ms;

  &:hover {
    background: #ddd;
    cursor: pointer;
    box-shadow: none;
  }
`

const StyledContactDetails = styled.div`
  flex-grow: 1;
`

const StyledName = styled.div`
  font-weight: bold;
  padding-right: 6px;
`

const StyledEmail = styled.div`
  font-size: 14px;
`

const StyledButton = styled(Button)`
  margin-top: 12px;
`
