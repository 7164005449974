import React from 'react'
import styled from 'styled-components'
import EmphasizedText from '../../shared/EmphasizedText'
import Button from '../../shared/Button'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function NetworkActivityLogOnboarding({ job, advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>Lovely — you're all set for your chat.</StyledContent>

      <StyledContent>
        You may find it useful to{' '}
        <EmphasizedText>keep a log of your meetings</EmphasizedText> with each
        contact. Within the contacts tab (
        <i
          className="material-icons"
          style={{ fontSize: '18px', display: 'inline-block' }}
        >
          contacts
        </i>
        ) on the left, you'll find space to summarize what you learned from each
        chat and to define your next steps.
      </StyledContent>

      <div>When you're ready, move on to make your final resume tweaks.</div>

      <ButtonWrapper>
        <Button onPress={() => advanceStage('TweakResume')}>
          Tweak Resume
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
