import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'
import Button from '../../shared/Button'

export default class IanInterviewPrep extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Get email tips" icon="💌" />

        <p>
          Sign up for a free email course from experienced hiring manager and
          career coach, Ian Douglas.
        </p>

        <p>
          Each day you'll get tips for answering one popular interview question.
          Questions range from culture fit to technical in scope.
        </p>

        <Button
          onPress={() =>
            window.open(
              'https://iandouglas.com/technical-coaching/interview-prep/',
              '_blank'
            )
          }
        >
          Sign Up
        </Button>
      </div>
    )
  }
}
