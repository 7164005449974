import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function ApplicationSubmitted({ advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        Well done getting an{' '}
        <EmphasizedText>application submitted</EmphasizedText>!
        <span
          style={{ display: 'inline-block', marginLeft: 3 }}
          role="img"
          aria-label="tada-emoji"
        >
          🎉
        </span>
      </StyledContent>

      <StyledContent>
        There's not much for you to do now, but wait for a response.
      </StyledContent>

      <StyledQuote>
        Note: a <i>Code Challenge</i> may also be referred to as a{' '}
        <i>take-home challenge</i> or other similar descriptions. It is a
        request to write and submit some code before a technical interview takes
        place.
      </StyledQuote>

      <ButtonWrapper>
        <Button margin="right" onPress={() => advanceStage('Retrospective')}>
          Rejected
        </Button>
        <Button margin="right" onPress={() => advanceStage('CodeChallenge')}>
          Code Challenge
        </Button>
        <Button onPress={() => advanceStage('RoundOneInterviewType')}>
          Interview Scheduled
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`

const StyledQuote = styled.div`
  border-left: 3px solid #777;
  padding: 12px;
  background: #eee;
`
