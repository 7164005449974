export default [
  {
    title: 'Blog',
    icon: 'rate_review',
    complete: false,
    description:
      'Clearly communicating complex ideas is a crucial skill in software development. Blogging is a great way to demonstrate that skill, while building a personal brand.',
    steps: [
      { componentType: 'SelectHosting', complete: false },
      { componentType: 'BrainstormContent', complete: false },
      { componentType: 'ScheduleWriting', complete: false },
      { componentType: 'OutlinePost', complete: false },
      { componentType: 'Write', complete: false },
      { componentType: 'FinishingTouches', complete: false },
    ],
  },
  {
    title: 'Give a talk',
    icon: 'campaign',
    complete: false,
    description:
      'Nothing builds instant credibility like being the person with the mic. Even a five-minute lightning talk can put you on the radar of hiring companies.',
    steps: [
      { componentType: 'BrainstormTalkContent', complete: false },
      { componentType: 'FindVenue', complete: false },
      { componentType: 'WriteTalk', complete: false },
      { componentType: 'PracticeTalk', complete: false },
      { componentType: 'DeliverTalk', complete: false },
    ],
  },
  {
    title: 'Volunteer',
    icon: 'pan_tool',
    complete: false,
    description:
      'Helping out a local Meetup group or conference can be an easy way to grow your network quickly.',
    steps: [
      { componentType: 'ResearchMeetups', complete: false },
      { componentType: 'ResearchConferences', complete: false },
      { componentType: 'VolunteerReachOut', complete: false },
    ],
  },
  {
    title: 'Build a new community',
    icon: 'construction',
    complete: false,
    description: "If you don't see a niche user group out there, create one!",
    steps: [
      { componentType: 'IdentifyOpportunities', complete: false },
      { componentType: 'FirstMembers', complete: false },
      { componentType: 'BrainstormSponsors', complete: false },
      { componentType: 'Launch', complete: false },
    ],
  },
  {
    title: 'Learn a new skill',
    icon: 'emoji_objects',
    complete: false,
    description:
      'Picking up a new language or library can broaden your understanding of software, but also communicate your versatility to employers.',
    steps: [
      { componentType: 'IdentifyInterests', complete: false },
      { componentType: 'ScopeProject', complete: false },
      { componentType: 'SkillBlog', complete: false },
    ],
  },
  // {
  // title: 'Contribute to open source',
  // icon: 'pest_control',
  // complete: false,
  // description:
  // "Open source contributions make the software world a better place, but they're also potential indicators of skill, persistence, and communication ability.",
  // steps: [
  // { componentType: 'Placeholder', complete: false },
  // { componentType: 'Placeholder', complete: false },
  // { componentType: 'Placeholder', complete: false },
  // { componentType: 'Placeholder', complete: false },
  // ],
  // },
]
