import React, { useState } from 'react'
import styled from 'styled-components'
import Tooltip from 'react-tooltip'
import dayjs from 'dayjs'
import NewActivityForm from './NewActivityForm'
import EditActivityForm from './EditActivityForm'
import Button from '../../../shared/Button'

export default function ContactDetail({
  jobId,
  contact,
  viewContactList,
  viewEditForm,
}) {
  const [activityFormVisible, setActivityFormVisible] = useState(false)
  const [editActivityId, setEditActivityId] = useState('')

  if (editActivityId) {
    const activity = contact.contactActivities.find(
      activity => activity.id === editActivityId
    )
    return (
      <EditActivityForm
        activity={activity}
        jobId={jobId}
        contactId={contact.id}
        closeForm={() => setEditActivityId('')}
      />
    )
  }

  return (
    <StyledWrapper>
      <Tooltip
        id="contact-content"
        className="react-tooltip-overrides"
        effect="solid"
        type="light"
        border
      />
      <StyledContactHeader>
        <StyledTitleWrapper>
          <StyledButton size="icon" onPress={viewContactList}>
            <i style={{ fontSize: '28px' }} className="material-icons">
              arrow_back
            </i>
          </StyledButton>

          <StyledNameWrapper>
            <StyledName>{contact.name}</StyledName>
            <StyledEmail>{contact.email}</StyledEmail>
          </StyledNameWrapper>

          <StyledButton size="icon" onPress={viewEditForm}>
            <i style={{ fontSize: '28px' }} className="material-icons">
              edit
            </i>
          </StyledButton>
        </StyledTitleWrapper>
        {contact.notes && <StyledNotes>{contact.notes}</StyledNotes>}
      </StyledContactHeader>

      <div>
        <StyledActivityTitle>Interactions</StyledActivityTitle>
        {contact.contactActivities.length > 0 ? (
          <>
            {contact.contactActivities
              .sort((a, b) => a.date < b.date)
              .map(activity => (
                <StyledActivity key={activity.id}>
                  <div>
                    <StyledActivityEditButton
                      size="icon"
                      onPress={() => setEditActivityId(activity.id)}
                    >
                      <i
                        style={{ fontSize: '28px' }}
                        className="material-icons"
                      >
                        edit
                      </i>
                    </StyledActivityEditButton>
                    <StyledDate>
                      {dayjs(parseInt(activity.date)).format('M/D/YYYY')} —{' '}
                      {activity.medium}
                    </StyledDate>
                  </div>
                  <div>
                    <b>Learned:</b> {activity.learned}
                  </div>
                  <div>
                    <b>Next steps:</b> {activity.nextSteps}
                  </div>
                </StyledActivity>
              ))}
          </>
        ) : (
          <div>
            <i>None logged yet.</i>
          </div>
        )}

        {activityFormVisible ? (
          <NewActivityForm
            jobId={jobId}
            contactId={contact.id}
            closeForm={() => setActivityFormVisible(false)}
          />
        ) : (
          <StyledSubmitButton onPress={() => setActivityFormVisible(true)}>
            Log Interaction
          </StyledSubmitButton>
        )}
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContactHeader = styled.div`
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 12px;
  padding-bottom: 12px;
`

const StyledTitleWrapper = styled.div`
  display: flex;
`

const StyledNameWrapper = styled.div`
  flex-grow: 1;
`

const StyledName = styled.div`
  font-size: 20px;
  font-weight: bold;
`

const StyledEmail = styled.div``

const StyledButton = styled(Button)`
  transform: scale(0.75);
  margin-left: -5px;
`

const StyledActivityEditButton = styled(Button)`
  transform: scale(0.5);
  position: absolute;
  top: -7px;
  right: -7px;
`

const StyledNotes = styled.div`
  background: white;
  font-style: italic;
  margin-top: 12px;
`

const StyledActivityTitle = styled.div`
  font-weight: bold;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px !important;
`

const StyledActivity = styled.div`
  position: relative;
  margin-top: 12px;
  padding: 6px;
  background: #eee;
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;
`

const StyledDate = styled.div`
  font-style: italic;
  border-bottom: 1px solid #ddd;
  padding-bottom: 4px;
  margin-bottom: 4px;
`
