import gql from 'graphql-tag'

export const CREATE_ORG_GROUP = gql`
  mutation CreateOrgGroup($input: CreateOrgGroupInput!) {
    createOrgGroup(input: $input) {
      id
      name
    }
  }
`

export const EDIT_ORG_GROUP = gql`
  mutation EditOrgGroup($input: EditOrgGroupInput!) {
    editOrgGroup(input: $input)
  }
`

export const ADD_USER_TO_ORG_GROUP = gql`
  mutation AddUserToOrgGroup($input: UpdateUserOrgGroupInput!) {
    addUserToOrgGroup(input: $input)
  }
`

export const REMOVE_USER_FROM_ORG_GROUP = gql`
  mutation RemoveUserFromOrgGroup($input: UpdateUserOrgGroupInput!) {
    removeUserFromOrgGroup(input: $input)
  }
`

export const REMOVE_USER_FROM_ORG = gql`
  mutation RemoveUserFromOrg($input: RemoveUserOrgInput!) {
    removeUserFromOrg(input: $input)
  }
`

export const CREATE_JOB = gql`
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      id
      title
      url
      company {
        name
      }
    }
  }
`

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      name
      email
      notes
    }
  }
`

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      id
      name
      email
      notes
    }
  }
`

export const DELETE_CONTACT = gql`
  mutation DeleteContact($input: DeleteContactInput!) {
    deleteContact(input: $input)
  }
`

export const CREATE_CONTACT_ACTIVITY = gql`
  mutation CreateContactActivity($input: CreateContactActivityInput!) {
    createContactActivity(input: $input) {
      id
      date
      medium
      learned
      nextSteps
    }
  }
`

export const UPDATE_CONTACT_ACTIVITY = gql`
  mutation UpdateContactActivity($input: UpdateContactActivityInput!) {
    updateContactActivity(input: $input) {
      id
      date
      medium
      learned
      nextSteps
    }
  }
`

export const DELETE_CONTACT_ACTIVITY = gql`
  mutation DeleteContactActivity($input: DeleteContactActivityInput!) {
    deleteContactActivity(input: $input)
  }
`

export const CREATE_SUGGESTED_JOB = gql`
  mutation CreateSuggestedJob($input: CreateJobInput!) {
    createSuggestedJob(input: $input)
  }
`

export const COMPLETE_STEP = gql`
  mutation COMPLETE_STEP($input: CompleteStepInput!) {
    completeStep(input: $input) {
      id
      code
    }
  }
`

export const RESET_STEPS = gql`
  mutation RESET_STEPS {
    resetSteps
  }
`

export const DELETE_USER_JOB = gql`
  mutation DeleteUserJob($input: DeleteUserJobInput!) {
    deleteUserJob(input: $input)
  }
`

export const UPDATE_USER_JOB = gql`
  mutation UpdateUserJob($input: UpdateUserJobInput!) {
    updateUserJob(input: $input) {
      id
      company {
        id
        name
      }
      url
      stage
      notes
    }
  }
`

export const SIGN_IN = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      email
      token
    }
  }
`

export const SIGN_UP = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      email
      token
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      email
    }
  }
`
