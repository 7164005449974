import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Select from 'react-select'
import { QUERY_ORGS } from '../../gql/queries'
import { SIGN_UP } from '../../gql/mutations'
import Button from '../shared/Button'

export default function NewJobForm() {
  const [org, setOrg] = useState({ label: '(None)', value: '' })
  const [roleId, setRoleId] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { data: orgData } = useQuery(QUERY_ORGS)
  const [createUser, { loading }] = useMutation(SIGN_UP, {
    refetchQueries: ['QUERY_USERS'],
  })

  // TODO: when org updates, query for its roles

  function resetForm() {
    setFirstName('')
    setLastName('')
    setEmail('')
    setPassword('')
    // setOrg('')
    setRoleId('')
  }

  function handleClick() {
    const user = {
      email: email.toLowerCase(),
      firstName,
      lastName,
      password,
      orgId: org.value,
      roleId,
    }
    console.log('∆∆∆ creating user', user)
    createUser({ variables: { input: { ...user } } })
    resetForm()
  }

  let orgOptions = []
  if (orgData && orgData.orgs) {
    orgOptions = orgData.orgs.map(org => ({
      label: org.name,
      value: org.id,
    }))
  }
  orgOptions.unshift({ value: '', label: '(None)' })
  // let roleOptions = []

  return (
    <StyledWrapper>
      {/*
      <StyledSelect placeholder="role" options={roleOptions} value={roleId} onChange={e => setRoleId(e)} />
      */}
      <Select
        placeholder="organization"
        options={orgOptions}
        value={org}
        onChange={org => setOrg(org)}
      />
      <StyledInput
        value={roleId}
        onChange={e => setRoleId(e.target.value)}
        placeholder="role ID"
        type="text"
      />
      <StyledInput
        value={firstName}
        onChange={e => setFirstName(e.target.value)}
        placeholder="first name"
        type="text"
      />
      <StyledInput
        value={lastName}
        onChange={e => setLastName(e.target.value)}
        placeholder="last name"
        type="text"
      />
      <StyledInput
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder="email address"
        type="text"
      />
      <StyledInput
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder="password"
        type="text"
      />
      <StyledSubmitButton
        disabled={!email || !password || loading}
        onPress={handleClick}
      >
        Create User
      </StyledSubmitButton>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  background-color: white;
  padding: 12px;
  border-radius: 6px;
  border: 3px dashed;
  max-width: 440px;
`

const StyledInput = styled.input`
  display: block;
  margin-top: 12px;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px !important;
`
