import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import AddJobModal from './AddJobModal'
import JobModal from './JobModal'
import MinimalJobCard from './MinimalJobCard'
import Button from '../../shared/Button'
import EmptyStateJobCard from './EmptyStateJobCard'

const variants = {
  open: {
    transition: { staggerChildren: 0.2, delayChildren: 1.2 },
  },
  closed: {
    transition: { staggerChildren: 0, delayChildren: 0 },
  },
}

const JOB_COLUMNS = {
  todo: [
    'GetExcited',
    'NetworkIntro',
    'NetworkCold',
    'NetworkAddContact',
    'NetworkContactOnboarding',
    'NetworkReachOut',
    'NetworkQuestions',
    'NetworkActivityLogOnboarding',
    'TweakResume',
    'CoverLetter',
    'NotYetSubmitted',
  ],
  applied: ['ApplicationSubmitted'],
  interviewing: [
    'CodeChallenge',
    'RoundOneInterviewType',
    'RoundOneInterviewPhoneScreen',
    'RoundOneInterviewTechScreen',
    'RoundOneThankYouPhoneScreen',
    'RoundOneThankYouTechScreen',
    'RoundOneResults',
    'RoundTwoInterviewType',
    'RoundTwoInterviewPhoneScreen',
    'RoundTwoInterviewTechScreen',
    'RoundTwoThankYouPhoneScreen',
    'RoundTwoThankYouTechScreen',
    'RoundTwoResults',
    'RoundThreeInterviewType',
    'RoundThreeInterviewPhoneScreen',
    'RoundThreeInterviewTechScreen',
    'RoundThreeThankYouPhoneScreen',
    'RoundThreeThankYouTechScreen',
    'RoundThreeResults',
  ],
  offer: ['ReceivedOffer'],
  accepted: ['AcceptedOffer'],
  rejected: ['Retrospective', 'DeclinedOffer', 'DoneRejected'],
}

export default function SwimLanes({ jobs, error }) {
  const [showNewJobModal, toggleShowNewJobModal] = useState(false)
  const [showJobModal, toggleShowJobModal] = useState(false)
  const [selectedJob, setSelectedJob] = useState(false)

  function filteredJobs(stage) {
    const filteredJobs = jobs.filter(job =>
      JOB_COLUMNS[stage].includes(job.stage)
    )
    return filteredJobs.map(job => (
      <MinimalJobCard
        key={job.id}
        job={job}
        selectJob={() => handleOpenJobModal(job.id)}
      />
    ))
  }

  function handleOpenJobModal(id) {
    setSelectedJob(id)
    toggleShowJobModal(true)
  }

  return (
    <StyledWrapper data-testid="swimlanes">
      <AddJobModal
        showModal={showNewJobModal}
        closeModal={() => toggleShowNewJobModal(false)}
      />

      <JobModal
        showModal={showJobModal}
        closeModal={() => toggleShowJobModal(false)}
        job={jobs.find(job => job.id === selectedJob)}
      />

      <ColumnsWrapper variants={variants}>
        <Column>
          <StyledAddJobButton
            disabled={!!error}
            onPress={() => toggleShowNewJobModal(true)}
            size="icon"
          >
            <i style={{ fontSize: '34px' }} className="material-icons">
              add
            </i>
          </StyledAddJobButton>
          <ColumnTitle>To Do</ColumnTitle>
          {filteredJobs('todo')}
          {!error && jobs.length === 0 && (
            <EmptyStateJobCard
              showNewJobModal={() => toggleShowNewJobModal(true)}
            />
          )}
          {!error && jobs.length === 1 && filteredJobs('todo').length === 1 && (
            <Tutorial>
              <TutorialBody>
                <div>Great opportunity!</div>
                <span
                  style={{ fontSize: 32, display: 'block', marginTop: '4px' }}
                  role="img"
                  aria-label="stars-emoji"
                >
                  🤩
                </span>
                <div>Click to view your next step.</div>
              </TutorialBody>
              <CurvedArrow />
            </Tutorial>
          )}
        </Column>

        <Column>
          <ColumnTitle>Applied</ColumnTitle>
          {filteredJobs('applied')}
        </Column>

        <Column>
          <ColumnTitle>Interviewing</ColumnTitle>
          {filteredJobs('interviewing')}
        </Column>

        <Column>
          <ColumnTitle>Offer</ColumnTitle>
          {filteredJobs('offer')}
        </Column>

        <Column>
          <ColumnTitle>Accepted</ColumnTitle>
          {filteredJobs('accepted')}
        </Column>

        <Column>
          <ColumnTitle>Rejected</ColumnTitle>
          {filteredJobs('rejected')}
        </Column>
      </ColumnsWrapper>

      {!error && jobs.length === 0 && (
        <EmptyState>
          This is not your average kanban board.
          <br />
          <br />
          There's no drag-and-drop functionality.
          <br />
          <br />
          The only way to move ahead is one step at a time.
          <br />
          <br />
          Add your first job to see your next step.
        </EmptyState>
      )}

      {error && (
        <EmptyState>
          <StyledError>
            Whoops! Something went wrong while fetching jobs.
          </StyledError>
        </EmptyState>
      )}
    </StyledWrapper>
  )
}

const blue1 = '#6a98c5'

const StyledWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`

const StyledAddJobButton = styled(Button)`
  transform: scale(0.55);
  position: absolute;
  right: 0px;
  top: -10px;
`

const ColumnsWrapper = styled(motion.div)`
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 12px;
`

const Column = styled.div`
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
  min-width: 220px;
  padding-bottom: 12px;
`

const ColumnTitle = styled.div`
  font-family: 'Dosis';
  font-weight: 800;
  font-size: 18px;
  text-transform: lowercase;
  text-align: center;
  color: #777;
`

const EmptyState = styled.div`
  text-align: center;
  color: #999;
  font-style: italic;
`

const StyledError = styled.p`
  margin-top: 30px;
`

const Tutorial = styled.div`
  position: relative;
  margin: 28px;
  border: 3px dashed ${blue1};
  border-radius: 6px;
  text-align: center;
`

const TutorialBody = styled.div`
  position: relative;
  background: white;
  padding: 12px;
  z-index: 2;
`

const CurvedArrow = styled.div`
  z-index: 1;
  position: absolute;
  left: -22px;
  top: -16px;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-right: 9px solid ${blue1};
  transform: rotate(70deg) scaleY(-1) scaleX(-1) scale(1.75);

  &:after {
    content: '';
    position: absolute;
    border: 0 solid transparent;
    border-top: 4px solid ${blue1};
    border-radius: 25px 7px 0 0;
    top: -11px;
    left: -19px;
    width: 26px;
    height: 16px;
    transform: rotate(23deg);
  }
`
