import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from './rootReducer'

export const history = createBrowserHistory()

export default function configureStore() {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  )

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      const nextPersistedReducer = require('./rootReducer').default // eslint-disable-line
      store.replaceReducer(nextPersistedReducer)
    })
  }

  return store
}
