import React, { Component } from 'react'
import styled from 'styled-components'
import SubstepTitle from '../SubstepTitle'
import resumeA from '../../../assets/images/resume-a.png'

export default class StarterTemplates extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Get off the ground" icon="🛫" />

        <p>
          Are you starting from scratch or need to overhaul your resume? Try our
          starter template.
        </p>

        <a
          href="https://docs.google.com/document/d/1iNh_89bXDBwD6Ra0BtToAq-vNA3xAIhOy3z7XtejsrM/edit?usp=sharing"
          target="blank"
        >
          <StyledTemplate src={resumeA} />
        </a>
      </div>
    )
  }
}

const StyledTemplate = styled.img`
  display: inline-block;
  max-width: 320px;
  max-height: 120px;
  object-fit: cover;
  object-position: 0 0;
  box-shadow: 0 3px 4px #ccc;
  margin: 12px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`
