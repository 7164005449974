import React from 'react'
import AnimateHeight from 'react-animate-height'
import styled, { css } from 'styled-components'
import Components from './goalComponents'
import Placeholder from './Placeholder'

const components = { ...Components }

export default function StepContainer({
  complete,
  title,
  icon,
  active,
  stepIndex,
  activeSubstep,
  setActive,
  advanceStep,
  componentType,
}) {
  function renderSubstepComponent(componentType) {
    const Component = components[componentType]
    if (!Component) return <Placeholder />
    return <Component />
  }

  return (
    <StyledContainer
      active={active}
      duration={1000}
      height={active ? 'auto' : 42}
    >
      <StyledContainer
        onClick={() => setActive(stepIndex, 0)}
        substep
        complete={complete}
        duration={1000}
        active
        height="auto"
      >
        <StyledSubstepBody substep complete={complete} active>
          {renderSubstepComponent(componentType)}
        </StyledSubstepBody>
        {/* <DoneButton advanceStep={advanceStep} stepKey={componentType} /> */}
      </StyledContainer>
    </StyledContainer>
  )
}

// const activeColor = '#FFF0B8'
// const completedColor = '#e1fed7'

const StyledContainer = styled(AnimateHeight)`
  box-shadow: 0 3px 4px #ccc;
  background-color: white;
  opacity: 0.5;
  border-radius: 6px;
  transition: opacity 0.3s;

  :first-child {
    box-shadow: none;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      // padding: 0 0 1px;
    `}

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    `}

  ${({ substep }) =>
    !substep &&
    css`
      margin-top: 12px;
    `}
`

const StyledSubstepBody = styled.div`
  padding: 12px;
`
