import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'

export default class Breathe extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Breathe" icon="💨" />

        <p>
          Getting your first developer job can be a grind. It may take a while
          and you may encounter some stress along the way.
        </p>

        <p>
          <i>So, take a nice big breath.</i>
        </p>

        <span style={{ fontSize: 38 }} role="img" aria-label="breath-emoji">
          🌬
        </span>
      </div>
    )
  }
}
