import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'
import Resources from './Resources'

export default function RoundThreeInterviewTechScreen({ advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        Great - you have a <EmphasizedText>technical interview</EmphasizedText>{' '}
        scheduled!
      </StyledContent>
      <StyledContent>
        It's natural to be nervous, but you can do this.
        <span
          style={{ display: 'inline-block', marginLeft: 3 }}
          role="img"
          aria-label="muscle-emoji"
        >
          💪
        </span>
      </StyledContent>
      <StyledContent>
        Use the resources below to study up and give it your best shot.
      </StyledContent>

      <Resources>
        <li>
          Turing School's{' '}
          <a
            href="https://github.com/turingschool/career-development-curriculum/blob/master/module_four/interview_prep_resources.md"
            target="_blank"
            rel="noopener noreferrer"
          >
            Interview Prep Resources
          </a>
        </li>
        <li>
          Ian Douglas's{' '}
          <a
            href="https://techinterview.guide/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Technical Interview Prep
          </a>{' '}
          book
        </li>
      </Resources>

      <ButtonWrapper>
        <Button onPress={() => advanceStage('RoundThreeThankYouTechScreen')}>
          Completed Interview
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
