import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'

export default class Smile extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Smile" icon="😁" />

        <p>
          Interviews are much more than just technical tests; your interviewers
          are deciding whether they would want to work alongside you every day.
        </p>

        <p>
          Don't forget to be a friendly human along the way. Take an interest in
          your potential colleagues, send thank you notes, and smile!
        </p>
      </div>
    )
  }
}
