import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Redirect, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { useToasts } from 'react-toast-notifications'
import { history } from '../store'
import Admin from './admin/Admin'
import Core from './Core'
import FlowCore from './FlowCore'
import Login from './Login'
import Password from './Password'
import Snapshot from './orgs/Snapshot'
import { checkIfLoggedIn } from '../store/auth/actions'
import { dismissError } from '../store/errors/actions'

function App() {
  const { addToast } = useToasts()
  const showLogin = useSelector(state => state.ui.showLogin)
  const error = useSelector(state => state.errors.message)
  const dispatch = useDispatch()
  dispatch(checkIfLoggedIn())

  useEffect(() => {
    if (error)
      addToast(error, {
        appearance: 'error',
        onDismiss: () => dispatch(dismissError()),
      })
  }, [error])

  if (showLogin)
    return (
      <ConnectedRouter history={history}>
        <Login />
      </ConnectedRouter>
    )

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/">
          <FlowCore />
        </Route>
        <Route exact path="/tracker">
          <FlowCore />
        </Route>
        <Route exact path="/old">
          <Core />
        </Route>
        <Route exact path="/admin">
          <Admin />
        </Route>
        <Route exact path="/password">
          <Password />
        </Route>
        <Route exact path="/snapshot">
          <Snapshot />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </ConnectedRouter>
  )
}

export default App
