import React from 'react'
import GoalTitle from '../GoalTitle'

export default function IdentifyInterests() {
  return (
    <div>
      <GoalTitle title="Identify your interests" icon="looks_one" />

      <p>
        If you're going to add anything else on your plate, you really should
        enjoy it. Is there anything you've been itching to learn? A few ideas:
      </p>

      <ul>
        <li>a new programming language</li>
        <li>a new library/tool/framework</li>
        <li>game development</li>
        <li>data science or data visualization</li>
        <li>blockchain technology</li>
        <li>3D art</li>
      </ul>

      <p>
        Don't spread yourself too thin. Limit yourself to just one
        extra-curricular item until you know you can handle the workload.
      </p>
    </div>
  )
}
