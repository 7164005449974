import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'
import ListItem from '../ListItem'

export default class Tailor extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Include a summary" icon="🌎" />

        <p>
          You may benefit from small tweaks to your resume for each company you
          apply to.
        </p>

        <p>
          Summary statements can be a great way to tailor your resume for the
          job. A couple examples:
        </p>

        <ul>
          <ListItem
            text="Convey your values"
            subtext="e.g. I’m a passionately curious developer looking to join a team that is proud of the software they build. A staunch believer in pair programming, my ideal environment is one that celebrates collaboration. I enjoying sharing what I learn and have written a number of blog posts here (link)."
          />
          <ListItem
            text="Explain your passion for their industry or technology"
            subtext="e.g. I believe blockchain technology has great potential to address systemic injustices, but know the greatest challenge for adoption today is the user experience. I'm eager to join a team tackling this issue head on."
          />
        </ul>
      </div>
    )
  }
}
