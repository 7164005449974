import React from 'react'
import ReactModal from 'react-modal'
import Tooltip from 'react-tooltip'

// Note: while testing, #root doesn't exist
if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root')
}

export default function Modal({ children, showModal }) {
  return (
    <>
      <ReactModal
        closeTimeoutMS={500}
        onAfterOpen={() => Tooltip.rebuild()}
        onAfterClose={() => Tooltip.hide()}
        style={{
          overlay: { zIndex: 999 },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '340px',
            padding: '0px',
            outline: 'none',
            border: 'none',
            borderRadius: '6px',
            boxShadow: '0 3px 4px #ccc',
            maxHeight: '100%',
          },
        }}
        isOpen={showModal}
      >
        {children}
      </ReactModal>

      <Tooltip
        className="react-tooltip-overrides"
        effect="solid"
        type="light"
        border
      />
      <Tooltip
        id="vertical-nav-tabs"
        className="react-tooltip-overrides"
        place="right"
        effect="solid"
        type="light"
        border
      />
    </>
  )
}
