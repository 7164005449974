import React from 'react'
import styled from 'styled-components'

export default function DoneRejected() {
  return <StyledWrapper>Rejected</StyledWrapper>
}
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
