import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'

export default class Blog extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Mentor" icon="👩‍🏫" />

        <p>
          If you received any mentorship along this path, then you know that it
          can make a <i>huge </i> difference in the journey to becoming a
          software developer.
        </p>

        <p>
          You don't have to do it for purely altruistic reasons. By teaching,
          you will also learn a ton and reveal knowledge gaps you didn't know
          you had.
        </p>

        <p>
          Reach out to your local code school or nonprofit training organization
          for details on how to get started.
        </p>
      </div>
    )
  }
}
