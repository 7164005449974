import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { push } from 'connected-react-router'

export function login({ token }) {
  return dispatch => {
    Cookies.set('tk', token, { sameSite: 'strict' })
    const userData = jwtDecode(token)
    dispatch(loginSuccess(userData))
    if (userData.role === 'staff') {
      dispatch(push('/snapshot'))
    }
  }
}

function loginSuccess({ email, role, id }) {
  return { type: 'LOGIN', payload: { email, role, id } }
}

export function logout(expiredToken = false) {
  return dispatch => {
    Cookies.set('tk', '', { sameSite: 'strict' })
    dispatch(push('/'))
    dispatch(logoutSuccess(expiredToken))
  }
}

function logoutSuccess(expiredToken) {
  return { type: 'LOGOUT', payload: { expiredToken } }
}

export function checkIfLoggedIn() {
  const token = Cookies.get('tk')
  if (token) {
    return loginSuccess(jwtDecode(token))
  }

  return { type: 'LOGOUT' }
}
