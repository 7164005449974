import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function RoundThreeThankYouPhoneScreen({ advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        You survived the phone screen!
        <span
          style={{ display: 'inline-block', marginLeft: 5 }}
          role="img"
          aria-label="clap-emoji"
        >
          👏
        </span>
      </StyledContent>

      <StyledContent>
        Email a quick <EmphasizedText>thank you note</EmphasizedText> to the
        interviewer(s) for their time. Keeping it short, polite, and casual is
        generally appropriate. Here's an example you can elaborate on:
      </StyledContent>

      <StyledQuote>
        <i>
          Hi <b>[name]</b>, thanks again for your time today. I enjoyed our
          conversation about life at <b>[company]</b> and look forward to
          hearing from you again. Have a great week, <b>[your name]</b>
        </i>
      </StyledQuote>

      <ButtonWrapper>
        <Button
          margin="right"
          onPress={() => advanceStage('RoundThreeResults')}
        >
          Note Sent
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`

const StyledQuote = styled.div`
  border-left: 3px solid #777;
  padding: 12px;
  background: #eee;
`
