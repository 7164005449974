import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../../shared/Button'

class SimpleJobCard extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  }

  render() {
    const { advanceStage, job } = this.props

    // TODO: generalize
    return (
      <StyledJobWrapper>
        <b>{job.companyName}</b>
        <Button fullWidth onPress={advanceStage}>
          Application Submitted
        </Button>
      </StyledJobWrapper>
    )
  }
}

export default SimpleJobCard

const StyledJobWrapper = styled.div`
  margin-top: 12px;
`
