import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import SubstepTitle from '../flowsteps/SubstepTitle'
import Button from '../shared/Button'
import FadeIn from './FadeIn'

export default function Intro({ advanceStep, stepKey }) {
  const parentVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.8, delayChildren: 2, delay: 1 },
    },
    closed: {
      opacity: 0,
      transition: { staggerChildren: 1, delayChildren: 2 },
    },
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <SubstepTitle title="What is this?" />

      <AnimatePresence>
        <motion.div variants={parentVariants} initial="closed" animate="open">
          <FadeIn>
            <p>
              These cards offer <HighlightedText>tips</HighlightedText>,{' '}
              <HighlightedText>tasks</HighlightedText>, and{' '}
              <HighlightedText>advice</HighlightedText>
              <br />
              relevant to all jobs you might apply for.
            </p>
          </FadeIn>

          <FadeIn>
            <p>
              <span
                style={{
                  display: 'inline-block',
                  fontSize: 18,
                  marginRight: 3,
                }}
                role="img"
                aria-label="sparkle-emoji"
              >
                ✨
              </span>
              Over time, new cards will appear here.
              <span
                style={{ display: 'inline-block', fontSize: 18, marginLeft: 3 }}
                role="img"
                aria-label="sparkle-emoji"
              >
                ✨
              </span>
            </p>
          </FadeIn>

          <FadeIn>
            <Button onPress={() => advanceStep(stepKey)}>Got it</Button>
          </FadeIn>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

const HighlightedText = styled.span`
  font-family: 'Dosis';
  font-weight: 800;
  display: inline-block;
  color: #777;
  transition: 2s ease-in;

  &:hover {
    color: #ff6b6b;
    transform: translateY(-6px);
    transition: transform 0.8s;
  }
`
