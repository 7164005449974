import React from 'react'
import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from '../shared/VerticalTabs'
import Goals from './goals/'

export default function FlowStepCard({ children }) {
  return (
    <StyledWrapper>
      <Tabs>
        <TabList>
          <Tab data-tip="Next Step" data-for="flowstep-nav-tabs">
            <i className="material-icons">star</i>
          </Tab>
          <Tab data-tip="Goals" data-for="flowstep-nav-tabs">
            <i className="material-icons">emoji_events</i>
          </Tab>
        </TabList>

        <TabPanel>
          <StyledCardBody>{children}</StyledCardBody>
        </TabPanel>
        <TabPanel>
          <Goals />
        </TabPanel>
      </Tabs>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin: 0 auto;
  max-width: 440px;
  box-shadow: 0 3px 4px #ccc;
  border-radius: 6px;
`

const StyledCardBody = styled.div`
  padding: 24px 12px;
  background-color: white;
`
