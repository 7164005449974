import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import SubstepTitle from '../flowsteps/SubstepTitle'
import Button from '../shared/Button'
import FadeIn from './FadeIn'

export default function Smile({ advanceStep, stepKey }) {
  const parentVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.8, delayChildren: 2, delay: 1 },
    },
    closed: {
      opacity: 0,
      transition: { staggerChildren: 1, delayChildren: 2, delay: 1 },
    },
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <SubstepTitle title="Smile" />

      <AnimatePresence>
        <motion.div variants={parentVariants} initial="closed" animate="open">
          <FadeIn>
            Technical skills are important,
            <br />
            but they aren't everything.
          </FadeIn>

          <FadeIn>
            While you're networking and interviewing,
            <br />
            people are forming opinions about whether
            <br />
            they would like to work alongside you.
          </FadeIn>

          <FadeIn>
            <span style={{ fontSize: 20 }} role="img" aria-label="breath-emoji">
              🙂
            </span>
            <br />
            <i>
              So, don't forget to be a
              <br />
              friendly human along the way.
            </i>
            <br />
            <span style={{ fontSize: 20 }} role="img" aria-label="breath-emoji">
              🙃
            </span>
          </FadeIn>

          <FadeIn>
            Take an interest in your potential colleagues,
            <br />
            send thank you notes, and smile!
          </FadeIn>

          <FadeIn>
            <Button onPress={() => advanceStep(stepKey)}>Happy Days</Button>
          </FadeIn>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
