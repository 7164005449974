import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import SubstepTitle from '../flowsteps/SubstepTitle'
import Button from '../shared/Button'
import FadeIn from './FadeIn'

export default function YourStory({ advanceStep, stepKey }) {
  const parentVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.8, delayChildren: 2, delay: 1 },
    },
    closed: {
      opacity: 0,
      transition: { staggerChildren: 1, delayChildren: 2, delay: 1 },
    },
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <SubstepTitle title="Hone Your Story" />

      <AnimatePresence>
        <motion.div variants={parentVariants} initial="closed" animate="open">
          <FadeIn>
            While networking and interviewing,
            <br />
            you will inevitably hear:
          </FadeIn>

          <FadeIn>
            <span style={{ fontSize: 20 }} role="img" aria-label="spiral-emoji">
              🌀
            </span>
            <i>
              <b>Tell me about yourself. </b>
            </i>
            <span style={{ fontSize: 20 }} role="img" aria-label="spiral-emoji">
              🌀
            </span>
          </FadeIn>

          <FadeIn>
            You know it's coming, so there's
            <br />
            no reason not to have a good answer.
          </FadeIn>

          <FadeIn>
            <div>Pause now to write responses to:</div>
            <ul
              style={{
                marginTop: 6,
                fontStyle: 'italic',
                listStylePosition: 'inside',
                paddingLeft: 0,
              }}
            >
              <li>What were you doing before this?</li>
              <li>Why the change in direction?</li>
              <li>What excites you about this new path?</li>
            </ul>
          </FadeIn>

          <FadeIn>
            Connect the answers in a way that can be told
            <br />
            in one fluid story, in less than two minutes.
          </FadeIn>

          <FadeIn>
            <a
              href="https://techinterview.guide/section-2-preparation/chapter-4-personal-brand/1-sharing-your-introduction/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Want more tips?
            </a>
          </FadeIn>

          <FadeIn>
            <Button onPress={() => advanceStep(stepKey)}>Story Honed</Button>
          </FadeIn>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
