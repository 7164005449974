import React from 'react'
import styled, { css } from 'styled-components'

export default function Logo() {
  return (
    <>
      <HeaderLetter>e</HeaderLetter>
      <HeaderLetter>v</HeaderLetter>
      <HeaderLetter>e</HeaderLetter>
      <HeaderLetter>n</HeaderLetter>
      <HeaderLetter>t</HeaderLetter>
      <HeaderLetter>u</HeaderLetter>
      <HeaderLetter>a</HeaderLetter>
      <HeaderLetter>l</HeaderLetter>
      <HeaderLetter bold>d</HeaderLetter>
      <HeaderLetter bold>e</HeaderLetter>
      <HeaderLetter bold>v</HeaderLetter>
    </>
  )
}

const HeaderLetter = styled.span`
  font-family: 'Dosis';
  font-weight: 700;
  font-size: 28px;
  color: #777;
  display: inline-block;
  transition: 2s ease-in;
  font-weight: 600;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 800;
    `}

  &:hover {
    color: #ff6b6b;
    transform: translateY(-8px);
    transition: transform 0.8s;
  }
`
