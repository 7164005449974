import React from 'react'
import styled from 'styled-components'

export default function AcceptedOffer() {
  return (
    <StyledWrapper>
      <StyledEmoji>
        <span role="img" aria-label="tada-emoji">
          🎉
        </span>{' '}
        congrats!{' '}
        <span role="img" aria-label="tada-emoji">
          🎉
        </span>
      </StyledEmoji>

      <StyledContent>You're a professional software developer!</StyledContent>

      <StyledContent>
        All that hard work paid off and its time to embark on the next chapter
        in your career.
      </StyledContent>

      <div style={{ marginBottom: '32px' }}>
        <i>
          Now, do the staff a solid and
          <br />
          fill out your Employment Survey.
        </i>
        <span
          style={{ display: 'inline-block', marginLeft: 3 }}
          role="img"
          aria-label="wink-emoji"
        >
          😉
        </span>
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`

const StyledEmoji = styled.div`
  font-size: 32px;
  padding: 24px 0;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
