import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'
import ListItem from '../ListItem'

export default class Blog extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Blog about it" icon="✏️" />

        <p>
          One skill every employer can appreciate is the ability to clearly
          communicate complex ideas. Some ideas to blog about:
        </p>
        <ul>
          <ListItem text="A how-to guide" subtext="e.g. Routing in React" />
          <ListItem
            text="Fundamentals deep dive"
            subtext="e.g. What are closures in JavaScript?"
          />
          <ListItem
            text="Project retrospective"
            subtext="e.g. how you built something, what you learned"
          />
          <ListItem
            text="Personal reflection"
            subtext="e.g. how past careers helped you become a better developer"
          />
        </ul>
      </div>
    )
  }
}
