import React from 'react'
import styled from 'styled-components'
import Button from '../shared/Button'

export default function DoneButton({ advanceStep, stepKey }) {
  return (
    <StyledWrapper>
      {/*
      <Button
        margin="right"
        color="gray"
        onPress={() => advanceStep()}
        size="icon"
      >
        <i className="material-icons">redo</i>
      </Button>
      */}
      <Button onPress={() => advanceStep(stepKey)} size="icon">
        <i className="material-icons">check</i>
      </Button>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  text-align: right;
  padding: 0 12px 12px;
`
