import React from 'react'
import GoalTitle from '../GoalTitle'

export default function SkillBlog() {
  return (
    <div>
      <GoalTitle title="Blog about it" icon="looks_3" />

      <p>
        For bonus points, blog about your journey as you learn this new skill!
      </p>

      <p>
        Your reflections can help someone following in your footsteps, build
        your personal brand, and help solidify your learnings. See the blogging
        goal for tips on how to approach this.
      </p>
    </div>
  )
}
