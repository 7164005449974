import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../../shared/Button'

class CompletedState extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  }

  render() {
    const { showTracker } = this.props

    return (
      <div>
        <p>
          <b>All good!</b>
        </p>
        <p>Have more applications to track?</p>

        <StyledButtonWrapper>
          <Button onPress={showTracker}>Open Job Tracker</Button>
        </StyledButtonWrapper>
      </div>
    )
  }
}

export default CompletedState

const StyledButtonWrapper = styled.div`
  margin-top: 12px;
`
