import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'
import ListItem from '../ListItem'

export default class Specialize extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Specialize" icon="🎯" />

        <p>
          You can differentiate yourself by learning a skillset beyond the
          fundamentals.
        </p>

        <p>
          Don't spread yourself too thin; look for one field that really
          captures your attention. Some ideas:
        </p>

        <ul>
          <ListItem
            text="Low-level programming languages"
            subtext="e.g. build a tool in Go or Rust"
          />
          <ListItem
            text="Distributed systems"
            subtext="e.g. build a chat app in Elixir"
          />
          <ListItem
            text="Mobile development"
            subtext="e.g. build a mobile app in Kotlin, Swift, or React Native"
          />
          <ListItem text="Hardware" subtext="e.g. Raspberry Pi, 3D printing" />
          <ListItem
            text="Data science"
            subtext="e.g. regression analyses with Python and scikit-learn"
          />
          <ListItem
            text="Blockchain technology"
            subtext="e.g. build an Ethereum DApp"
          />
        </ul>

        <p>
          <b>Bonus points</b> for blogging about your exploration of one of
          these topics!
        </p>
      </div>
    )
  }
}
