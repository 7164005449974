import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'

export default class SkillList extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="List your skills" icon="🏅" />

        <p>
          Occasionally someone in HR with a checklist of skills will filter
          applications for the next round. Make sure you clearly include all of
          your skills that you think are most important for the job.
        </p>

        <p>See the templates in the "Get Off the Ground" step for examples.</p>
      </div>
    )
  }
}
