import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import Select from 'react-select'
import { QUERY_TRACKED_JOBS_STATS, QUERY_USERS_STATS } from '../../gql/queries'
import CustomizedAxisTick from '../../components/shared/CustomizedAxisTick'

export default function AdminStats() {
  const [trackedJobsRange, setTrackedJobsRange] = useState({
    label: '30 days',
    value: 30,
  })

  const { data: trackedJobsData, refetch: refetchTrackedJobsStats } = useQuery(
    QUERY_TRACKED_JOBS_STATS,
    {
      variables: { days: trackedJobsRange.value },
    }
  )

  useEffect(() => {
    refetchTrackedJobsStats()
  }, [trackedJobsRange])

  const [usersRange, setUsersRange] = useState({
    label: '30 days',
    value: 30,
  })

  const { data: usersData, refetch: refetchUsersStats } = useQuery(
    QUERY_USERS_STATS,
    {
      variables: { days: usersRange.value },
    }
  )

  useEffect(() => {
    refetchUsersStats()
  }, [usersRange])

  const rangeOptions = [
    { value: 30, label: '30 days' },
    { value: 60, label: '60 days' },
    { value: 90, label: '90 days' },
    { value: 180, label: '180 days' },
    { value: 365, label: '365 days' },
  ]

  return (
    <StyledWrapper>
      {trackedJobsData && (
        <>
          <h3>{`tracked jobs created last ${trackedJobsRange.label}`}</h3>
          <StyledSelect
            placeholder="30 days"
            options={rangeOptions}
            value={trackedJobsRange}
            onChange={rangeOption => setTrackedJobsRange(rangeOption)}
          />
          <ResponsiveContainer width={'100%'} height={300}>
            <LineChart
              data={trackedJobsData.trackedJobsStats.data}
              margin={{ top: 30, right: 30, left: 0, bottom: 90 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
              <YAxis />
              <Tooltip />
              <Line
                name="tracked jobs created"
                type="monotone"
                dataKey="data"
                stroke="#8884d8"
              />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
      {usersData && (
        <>
          <h3>{`new users created last ${usersRange.label}`}</h3>
          <StyledSelect
            placeholder="30 days"
            options={rangeOptions}
            value={usersRange}
            onChange={rangeOption => setUsersRange(rangeOption)}
          />
          <ResponsiveContainer width={'100%'} height={300}>
            <LineChart
              data={usersData.usersStats.data}
              margin={{ top: 30, right: 30, left: 0, bottom: 90 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
              <YAxis />
              <Tooltip />
              <Line
                name="users created"
                type="monotone"
                dataKey="data"
                stroke="#8884d8"
              />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSelect = styled(Select)`
  width: 150px;
  margin-top: 12px;
`
