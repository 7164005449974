import React from 'react'
import GoalTitle from '../GoalTitle'

export default function Launch() {
  return (
    <div>
      <GoalTitle title="Launch" icon="looks_4" />

      <p>
        Communities come in all shapes and sizes. It's up to you to decide how
        to foster yours.
      </p>

      <p>
        Do you want the real-time communication of a chat server, like Slack or
        Discord? Should you meet in person? How often? Do you prefer prepared
        talks or open discussion?
      </p>

      <p>
        Get some feedback from your first members, make some decisions, and give
        it a shot! You can always iterate based on what you learn about the
        needs of your new community.
      </p>
    </div>
  )
}
