import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import Select from 'react-select'
import { QUERY_USER_SNAPSHOT, QUERY_ORG_GROUPS } from '../../gql/queries'

export default function Progress() {
  const [filter, setFilter] = useState({ label: 'All', value: '' })
  const { data } = useQuery(QUERY_USER_SNAPSHOT)
  const { data: groupData } = useQuery(QUERY_ORG_GROUPS)

  let options = []
  if (groupData && groupData.orgGroups) {
    options = groupData.orgGroups.map(group => ({
      value: group.id,
      label: group.name,
    }))
  }
  options.unshift({ value: '', label: 'All' })

  function shouldDisplayUser(user) {
    return (
      user.role.name === 'student' &&
      (filter.label === 'All' ||
        user.orgGroups.map(g => g.id).includes(filter.value))
    )
  }

  return (
    <>
      <StyledFilters>
        Filter by:{' '}
        <StyledSelect
          value={filter}
          onChange={filter => setFilter(filter)}
          options={options}
        />
      </StyledFilters>

      <table className="eventual-table">
        <thead>
          <tr>
            <th>student</th>
            <th>steps</th>
            <th>watching</th>
            <th>applications</th>
            <th>interviews</th>
            <th>rejections</th>
            <th>offers</th>
            <th>accepted?</th>
            <th>survey?</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.userSnapshot.map(user => {
              if (shouldDisplayUser(user)) {
                return (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.completedStepCount}</td>
                    <td>{user.watching}</td>
                    <td>{user.appliedTo}</td>
                    <td>{user.interviews}</td>
                    <td>{user.rejections}</td>
                    <td>{user.offers}</td>
                    <td>
                      <i className="material-icons">
                        {user.accepted ? 'done' : 'close'}
                      </i>
                    </td>
                    <td>
                      <i className="material-icons">
                        {user.surveyCompleted ? 'done' : 'close'}
                      </i>
                    </td>
                  </tr>
                )
              }
              return null
            })}
        </tbody>
      </table>
    </>
  )
}

const StyledSelect = styled(Select)`
  display: inline-block;
  width: 300px;
`

const StyledFilters = styled.div`
  margin: 12px 0;
`
