import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { QUERY_TRACKED_JOB_CONTACTS } from '../../../../gql/queries'
import {
  CREATE_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
} from '../../../../gql/mutations'
import AddContactForm from './AddContactForm'
import EditContactForm from './EditContactForm'
import ContactList from './ContactList'
import ContactDetail from './ContactDetail'

export default function Contacts({ jobId }) {
  // TODO: provide routing/URLs for these?
  // Views: 'contactList', 'addContact', 'editContact', 'contactDetail', 'logActivity'
  const [contactContent, setContactContent] = useState('contactList')
  const [activeContactId, setActiveContactId] = useState('')
  const { data } = useQuery(QUERY_TRACKED_JOB_CONTACTS, {
    variables: { input: { jobId } },
  })
  const [createContact] = useMutation(CREATE_CONTACT, {
    refetchQueries: [
      {
        query: QUERY_TRACKED_JOB_CONTACTS,
        variables: { input: { jobId } },
      },
    ],
  })
  const [updateContact] = useMutation(UPDATE_CONTACT, {
    refetchQueries: [
      {
        query: QUERY_TRACKED_JOB_CONTACTS,
        variables: { input: { jobId } },
      },
    ],
  })
  const [deleteContact] = useMutation(DELETE_CONTACT, {
    refetchQueries: [
      {
        query: QUERY_TRACKED_JOB_CONTACTS,
        variables: { input: { jobId } },
      },
    ],
  })

  function handleCreateContact({ name, email, notes }) {
    createContact({ variables: { input: { jobId, name, email, notes } } })
    setContactContent('contactList')
  }

  function handleUpdateContact(contactId, { name, email, notes }) {
    updateContact({
      variables: { input: { jobId, contactId, name, email, notes } },
    })
    setContactContent('contactDetail')
  }

  function handleDeleteContact(contactId) {
    deleteContact({ variables: { input: { jobId, contactId } } })
    setContactContent('contactList')
  }

  function handleSetActiveContact(contactId) {
    setActiveContactId(contactId)
    setContactContent('contactDetail')
  }

  if (contactContent === 'addContact') {
    return (
      <AddContactForm
        cancelAdd={() => setContactContent('contactList')}
        addContact={handleCreateContact}
      />
    )
  }

  if (contactContent === 'contactList') {
    let contacts = []
    if (data && data.trackedJobContacts) {
      contacts = data.trackedJobContacts
    }
    return (
      <ContactList
        contacts={contacts}
        selectContact={id => handleSetActiveContact(id)}
        viewAddForm={() => setContactContent('addContact')}
      />
    )
  }

  // NOTE: the remaining views rely on contact data being availble,
  // and it should not be possible to get this far without it.
  const contact = data.trackedJobContacts.find(c => c.id === activeContactId)

  if (contactContent === 'contactDetail') {
    return (
      <ContactDetail
        jobId={jobId}
        contact={contact}
        selectContact={id => handleSetActiveContact(id)}
        viewContactList={() => setContactContent('contactList')}
        viewEditForm={() => setContactContent('editContact')}
      />
    )
  }

  if (contactContent === 'editContact') {
    return (
      <EditContactForm
        contact={contact}
        cancelEdit={() => setContactContent('contactDetail')}
        deleteContact={contactId => handleDeleteContact(contactId)}
        updateContact={updatedContact =>
          handleUpdateContact(contact.id, updatedContact)
        }
      />
    )
  }

  // NOTE: cases above should handle all UX.
  return <p>Whoops! Something went wrong.</p>
}
