// NOTE: temporarily used for Turing-only sign-ups.

export default [
  { id: '1', name: '1908 FE' },
  { id: '2', name: '1908 BE' },
  { id: '3', name: '1909 BE' },
  { id: '4', name: '1909 FE' },
  { id: '5', name: '1811 FE' },
  { id: '6', name: '1811 BE' },
  { id: '7', name: '1606 BE' },
  { id: '8', name: '1606 FE' },
  { id: '9', name: '1608 BE' },
  { id: '10', name: '1608 FE' },
  { id: '11', name: '1610 FE' },
  { id: '12', name: '1610 BE' },
  { id: '13', name: '1611 BE' },
  { id: '14', name: '1611 FE' },
  { id: '15', name: '1701 BE' },
  { id: '16', name: '1701 FE' },
  { id: '17', name: '1703 BE' },
  { id: '18', name: '1703 FE' },
  { id: '19', name: '1705 BE' },
  { id: '20', name: '1705 FE' },
  { id: '21', name: '1605 BE' },
  { id: '22', name: '1603 BE' },
  { id: '23', name: '1602 BE' },
  { id: '24', name: '1511 BE' },
  { id: '25', name: '1510 BE' },
  { id: '26', name: '1508 BE' },
  { id: '27', name: '1507 BE' },
  { id: '28', name: '1505 BE' },
  { id: '29', name: '1503 BE' },
  { id: '30', name: '1502 BE' },
  { id: '31', name: '1412 BE' },
  { id: '32', name: '1410 BE' },
  { id: '33', name: '1409 BE' },
  { id: '34', name: '1407 BE' },
  { id: '35', name: '1406 BE' },
  { id: '36', name: '1706 BE' },
  { id: '37', name: '1706 FE' },
  { id: '38', name: '1708 BE' },
  { id: '39', name: '1708 FE' },
  { id: '40', name: '1710 BE' },
  { id: '41', name: '1710 FE' },
  { id: '42', name: '1711 BE' },
  { id: '43', name: '1711 FE' },
  { id: '44', name: '1801 BE' },
  { id: '45', name: '1801 FE' },
  { id: '46', name: '1803 BE' },
  { id: '47', name: '1803 FE' },
  { id: '48', name: '1804 BE' },
  { id: '49', name: '1804 FE' },
  { id: '50', name: '1806 BE' },
  { id: '51', name: '1806 FE' },
  { id: '52', name: '1808 BE' },
  { id: '53', name: '1808 FE' },
  { id: '54', name: '1810 BE' },
  { id: '55', name: '1810 FE' },
  { id: '58', name: '1901 BE' },
  { id: '59', name: '1901 FE' },
  { id: '60', name: '1903 BE' },
  { id: '61', name: '1903 FE' },
  { id: '62', name: '1904 BE' },
  { id: '63', name: '1904 FE' },
  { id: '64', name: '1906 BE' },
  { id: '65', name: '1906 FE' },
  { id: '70', name: '1911 BE' },
  { id: '71', name: '1911 FE' },
  { id: '72', name: '2001 BE' },
  { id: '73', name: '2001 FE' },
  { id: '74', name: '2003 BE' },
  { id: '75', name: '2003 FE' },
  { id: '76', name: '2005 BE' },
  { id: '77', name: '2005 FE' },
  { id: '78', name: '2006 BE' },
  { id: '79', name: '2006 FE' },
  { id: '80', name: '2008 BE' },
  { id: '81', name: '2008 FE' },
  { id: '82', name: '2010 BE' },
  { id: '83', name: '2010 FE' },
  { id: '84', name: '2011 BE' },
  { id: '85', name: '2011 FE' },
]
