import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import Select from 'react-select'
import { SIGN_IN, SIGN_UP } from '../gql/mutations'
import Button from './shared/Button'
import Logo from './shared/Logo'
import { login } from '../store/auth/actions'
import cohorts from '../cohortData'
import Modal from './shared/Modal'

export default function Login() {
  const expiredToken = useSelector(state => state.auth.expiredToken)
  const [showRecoveryModal, toggleShowRecoveryModal] = useState(false)
  const [isSigningUp, toggleIsSigningUp] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [group, setGroup] = useState({ label: 'Cohort', value: '' })
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [signIn, { error: signInError, data: signInData }] = useMutation(
    SIGN_IN
  )
  const [
    createUser,
    { error: createUserError, data: createUserData },
  ] = useMutation(SIGN_UP)
  const dispatch = useDispatch()

  if (signInData && signInData.signIn.token) {
    dispatch(login(signInData.signIn))
  }

  if (createUserData && createUserData.createUser.token) {
    dispatch(login(createUserData.createUser))
  }

  function handleLoginOrSignUp(e) {
    e.preventDefault()

    if (isSigningUp) {
      if (!firstName || !lastName || !group.value || !email || !password) return
      const user = {
        email: email.toLowerCase(),
        firstName,
        lastName,
        password,

        // NOTE: Hard-coded Turing student values:
        orgId: '1',
        roleId: '1',

        // ID of Turing cohort:
        orgGroupId: group.value,
      }
      createUser({
        variables: { input: { ...user } },
      })
    } else {
      if (!email || !password) return
      signIn({
        variables: { input: { email: email.toLowerCase(), password } },
      })
    }
  }

  const groupOptions = cohorts
    .sort((a, b) => a.name < b.name)
    .map(group => ({
      label: group.name,
      value: group.id,
    }))

  return (
    <StyledContainer>
      <StyledLoginContainer>
        {expiredToken && (
          <StyledInfo>
            It looks like your session expired. Please log in again.
          </StyledInfo>
        )}
        <TitleWrapper>
          <Logo />
          <div>(A public beta for students and alum of Turing School)</div>
        </TitleWrapper>

        {signInError && (
          <StyledError>
            Whoops! Your username or password is incorrect.
          </StyledError>
        )}
        {createUserError &&
          (createUserError.message.includes(
            'SequelizeUniqueConstraintError'
          ) ? (
            <StyledError>Whoops! This email is already in use.</StyledError>
          ) : (
            <StyledError>Whoops! Something went wrong.</StyledError>
          ))}

        <form onSubmit={handleLoginOrSignUp}>
          <StyledWrapper>
            {isSigningUp && (
              <>
                <StyledInput
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  placeholder="First Name"
                  type="text"
                  data-testid="input-firstname"
                />
                <StyledInput
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  placeholder="Last Name"
                  type="text"
                  data-testid="input-firstname"
                />
                <StyledSelect
                  placeholder="group"
                  options={groupOptions}
                  value={group}
                  onChange={group => setGroup(group)}
                />
              </>
            )}
            <StyledInput
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
              type="text"
              data-testid="input-email"
            />
            <StyledInput
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
              data-testid="input-password"
            />
            <StyledSubmitButton
              submit
              type="secondary"
              disabled={
                isSigningUp
                  ? !email ||
                    !password ||
                    !firstName ||
                    !lastName ||
                    !group.value
                  : !email || !password
              }
            >
              {isSigningUp ? 'Sign up' : 'Log in'}
            </StyledSubmitButton>
          </StyledWrapper>
        </form>

        <StyledLinkButton onClick={() => toggleIsSigningUp(!isSigningUp)}>
          {isSigningUp ? 'Log in' : 'Sign up'}
        </StyledLinkButton>

        {!isSigningUp && (
          <>
            <Modal showModal={showRecoveryModal}>
              <StyledModalContent>
                <span
                  style={{ display: 'inline-block', fontSize: '24px' }}
                  role="img"
                  aria-label="breath-emoji"
                >
                  😅
                </span>
                <p>
                  There are still some rough
                  <br />
                  edges here in <i>beta</i> town.
                </p>
                <p>
                  Please reach out to
                  <br />
                  Marc (<b>@marcg</b>) or Andrew (<b>@carmer</b>)
                  <br />
                  on Turing's Slack for help.
                </p>
                <Button
                  onPress={() => toggleShowRecoveryModal(!showRecoveryModal)}
                  type="secondary"
                >
                  Fine
                </Button>
              </StyledModalContent>
            </Modal>
            <ForgotPasswordButton
              onClick={() => toggleShowRecoveryModal(!showRecoveryModal)}
            >
              Forgot password?
            </ForgotPasswordButton>
          </>
        )}
      </StyledLoginContainer>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLoginContainer = styled.div`
  position: relative;
  margin: 15vh auto;
  padding: 12px;
  background-color: #f8f8f8;
  box-shadow: 0 3px 4px #ccc;
  border-radius: 6px;
  width: 80%;
  max-width: 440px;
`

const TitleWrapper = styled.div`
  text-align: center;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInput = styled.input`
  margin-top: 12px;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px !important;
`

const StyledError = styled.div`
  color: rgb(229, 96, 96);
  margin-top: 6px;
`

const StyledInfo = styled.div`
  margin: 24px auto;
  text-align: center;
`

const StyledLinkButton = styled.button`
  display: block;
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
  margin: 12px auto 0;
  text-align: center;
  font-size: 13px;
`

const StyledSelect = styled(Select)`
  margin-top: 12px;
  z-index: 999 !important;
`

const ForgotPasswordButton = styled.button`
  position: absolute;
  right: 12px;
  bottom: 6px;
  display: block;
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
  margin: -6px 0 6px;
  text-align: right;
  font-size: 12px;
`

const StyledModalContent = styled.div`
  text-align: center;
  padding: 24px;
`
