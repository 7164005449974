import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'

export default function NetworkQuestions({ job, advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        Good move! Now,{' '}
        <EmphasizedText>
          come up with at least a handful of questions
        </EmphasizedText>{' '}
        to ask this person when you talk.
      </StyledContent>

      <div>
        A few examples:
        <ul>
          <li>What does your average day look like?</li>
          <li>How would you describe the culture there?</li>
          <li>Are you able to influence the direction of the product?</li>
        </ul>
      </div>

      <StyledContent>
        These types of questions are good starting points, but try to come up
        with some more about the product, team, or industry. It should be clear
        that you've done at least a little homework.
      </StyledContent>

      <div>
        Most of all, don't miss a chance to learn something about this person's
        journey leading up to, and within this company! Remember, you're
        building a relationship, not just checking off another to-do item.
      </div>

      <StyledButtonWrapper>
        <Button onPress={() => advanceStage('NetworkActivityLogOnboarding')}>
          Got My Questions
        </Button>
      </StyledButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
`
