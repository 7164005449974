import React from 'react'
import styled from 'styled-components'

export default function InterviewPrep({ children }) {
  return (
    <StyledWrapper>
      <StyledTitle>Resources:</StyledTitle>
      <StyledList>{children}</StyledList>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  color: #777;
  background: #f3f3f3;
  border-radius: 6px;
`

const StyledTitle = styled.div`
  font-family: 'Raleway', sans serif;
  font-weight: 200;
  font-size: 19px;
  color: #555;
  text-transform: lowercase;
`

const StyledList = styled.ul`
  padding-left: 20px;
  margin: 10px 0 2px;
  font-size: 15px;
`
