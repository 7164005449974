import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Progress } from 'react-sweet-progress'
import Components from '../stages'
import 'react-sweet-progress/lib/style.css'

const components = { ...Components }

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 100, velocity: -500, duration: 0.5 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 100 },
    },
  },
}

export default function JobCard({ job, selectJob }) {
  function calculateProgress() {
    // TODO: naive logic for prototype
    const numberOfStages = Object.keys(components).length
    const currentStageNumber = Object.keys(components).findIndex(
      s => s === job.stage
    )

    // finished stages:
    if (
      ['AcceptedOffer', 'DoneRejected', 'DeclinedOffer'].includes(job.stage)
    ) {
      return 100
    }

    // start with at least some progress:
    let progress = (currentStageNumber / numberOfStages) * 100 + 15
    if (progress > 100) progress = 100
    return progress
  }

  return (
    <StyledWrapper
      className="minimal-job-card"
      data-testid="job-card"
      onClick={selectJob}
      variants={variants}
    >
      <StyledCardTitle>
        <StyledCompanyName>{job.company.name}</StyledCompanyName>
        {job.title && <div>{job.title}</div>}
      </StyledCardTitle>

      <Progress
        theme={{ success: { color: blue1, trailColor: lblue2 } }}
        percent={calculateProgress()}
        status="success"
      />
    </StyledWrapper>
  )
}

// TODO: experimenting; standardize palette
const lblue3 = '#e4eff9'
const lblue2 = '#cadff3' // used as base at https://palx.jxnblk.com/
// const lblue1 = '#97bbdf'
const blue1 = '#6a98c5'
// const blue2 = '#4677A7'
// const blue3 = '#2d6193'
const gray3 = '#636d77'

const StyledWrapper = styled(motion.div)`
  margin: 12px auto;
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;
  width: 200px;
  transition: box-shadow 300ms;

  &:hover {
    box-shadow: 0 3px 0 #ccc;
    cursor: pointer;
  }
`

const StyledCardTitle = styled.div`
  padding: 12px;
  background-color: ${lblue3};
  color: ${gray3};
  border-radius: 6px 6px 0 0;
`

const StyledCompanyName = styled.div`
  font-weight: bold;
`
