export const initialState = {
  message: null,
}

export default function errors(state = initialState, action) {
  switch (action.type) {
    case 'ERRORS:NEW':
      return { ...state, message: action.error }
    case 'ERRORS:DISMISS':
      return { ...state, message: null }
    default:
      return state
  }
}
