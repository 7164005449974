import React from 'react'
import styled from 'styled-components'

export default function Button({ children, ...restProps }) {
  return <StyledButton {...restProps}>{children}</StyledButton>
}

const StyledButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #999;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
