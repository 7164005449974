import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import Cookies from 'js-cookie'
import { ToastProvider } from 'react-toast-notifications'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './react-dates-overrides.css'
import './components/shared/AwesomeButton/styles.css'
import './index.css'
import App from './components/App'
import configureStore from './store'
import { logout } from './store/auth/actions'
import { newError } from './store/errors/actions'
import * as serviceWorker from './serviceWorker'

const store = configureStore()

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, path, ...rest }) => {
      const e = `[GraphQL error]: ${message}, Path: ${path}`
      console.log('∆∆∆ e', rest)
      return store.dispatch(newError(e))
    })

    const expiredTokenError = graphQLErrors.find(err =>
      err.message.includes('jwt token expired')
    )

    const unauthenticatedError = graphQLErrors.find(
      err => err.extensions.code === 'UNAUTHENTICATED'
    )

    switch (true) {
      case !!expiredTokenError:
      case !!unauthenticatedError:
        store.dispatch(logout(true))
        break
      default:
        break
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get('tk')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  }
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URI || 'http://127.0.0.1:4000/graphql',
})

const link = ApolloLink.from([authLink, errorLink, httpLink])

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ToastProvider placement="bottom-left">
        <App />
      </ToastProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
