import React from 'react'
import styled from 'styled-components'

export default function ButtonWrapper({ children }) {
  return <StyledButtonWrapper>{children}</StyledButtonWrapper>
}

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
`
