import React from 'react'
import styled from 'styled-components'
import JobCard from './JobCard'
import Modal from '../../shared/Modal'

export default function JobModal({ showModal, closeModal, job }) {
  return (
    <Modal showModal={showModal}>
      <StyledBody>
        <JobCard job={job} isModal closeModal={closeModal} />
      </StyledBody>
    </Modal>
  )
}

const StyledBody = styled.div`
  width: 640px;
`
