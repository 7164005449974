import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'
import Resources from './Resources'

export default function TweakResume({ job, advanceStage }) {
  return (
    <StyledWrapper>
      <div>
        Small <EmphasizedText>resume tweaks</EmphasizedText> can add up.
      </div>

      <ul>
        <li style={{ marginBottom: '12px' }}>
          If your resume contains a list of skills, make sure it includes those
          that this employer is looking for -{' '}
          <i>only if you have the experience.</i>
        </li>
        <li>
          If you have a summary or objective section, make sure it aligns with
          this job and employer.
        </li>
      </ul>

      <Resources>
        <li>
          Turing School's{' '}
          <a
            href="https://github.com/turingschool/career-development-curriculum/blob/master/module_three/resume_review_checklist.md"
            target="_blank"
            rel="noopener noreferrer"
          >
            Resume Checklist
          </a>
        </li>
        <li>
          Ian Douglas's{' '}
          <a
            href="https://techinterview.guide/section-2-preparation/chapter-9-resumes/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Resume Preparation notes
          </a>
        </li>
      </Resources>

      <ButtonWrapper>
        <Button onPress={() => advanceStage('CoverLetter')}>
          Resume Ready
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
