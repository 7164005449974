import React from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import SubstepTitle from '../flowsteps/SubstepTitle'
import Button from '../shared/Button'
import FadeIn from './FadeIn'

export default function Welcome({ advanceStep, stepKey }) {
  const parentVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.8, delayChildren: 2, delay: 1 },
    },
    closed: {
      opacity: 0,
      transition: { staggerChildren: 1, delayChildren: 2 },
    },
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <SubstepTitle title="Welcome" />

      <AnimatePresence>
        <motion.div variants={parentVariants} initial="closed" animate="open">
          <FadeIn>
            <HeaderLetter>e</HeaderLetter>
            <HeaderLetter>v</HeaderLetter>
            <HeaderLetter>e</HeaderLetter>
            <HeaderLetter>n</HeaderLetter>
            <HeaderLetter>t</HeaderLetter>
            <HeaderLetter>u</HeaderLetter>
            <HeaderLetter>a</HeaderLetter>
            <HeaderLetter>l</HeaderLetter>
            <HeaderLetter bold>d</HeaderLetter>
            <HeaderLetter bold>e</HeaderLetter>
            <HeaderLetter bold>v</HeaderLetter> will help you prepare
            <br />
            for and land a developer job.
          </FadeIn>

          <FadeIn>Let's take it one step at a time.</FadeIn>

          <FadeIn>
            <Button onPress={() => advanceStep(stepKey)}>Begin</Button>
          </FadeIn>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

const HeaderLetter = styled.span`
  font-family: 'Dosis';
  font-weight: 600;
  display: inline-block;
  color: #777;
  transition: 2s ease-in;
  font-size: 19px;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 800;
    `}

  &:hover {
    color: #ff6b6b;
    transform: translateY(-6px);
    transition: transform 0.8s;
  }
`
