import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function NotYetSubmitted({ advanceStage }) {
  return (
    <StyledWrapper>
      <div>
        Once you've got all your ducks in a row, i.e.,
        <ul>
          <li>identified why you're interested in this opportunity,</li>
          <li>made a networking effort,</li>
          <li>tweaked your resume, and</li>
          <li>wrote a cover letter,</li>
        </ul>
        its time to <EmphasizedText>submit that application</EmphasizedText>!
      </div>

      <ButtonWrapper>
        <Button onPress={() => advanceStage('ApplicationSubmitted')}>
          Application Submitted
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
