import styled from 'styled-components'
import {
  Tab as UnstyledTab,
  TabList as UnstyledTabList,
  Tabs as UnstyledTabs,
  TabPanel as UnstyledTabPanel,
} from 'react-tabs'

const Tabs = styled(UnstyledTabs)`
  border: 1px solid #e0e0e0;
  display: flex;
`

const TabList = styled(UnstyledTabList)`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background: #ededed;
`

const Tab = styled(UnstyledTab).attrs({
  selectedClassName: 'selected',
  disabledClassName: 'disabled',
})`
  background: #ededed;
  text-align: center;
  padding: 12px;
  list-style: none;
  cursor: pointer;
  color: #888;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;

  &:first-child {
    border-left: none;
  }

  &.selected {
    color: #6a98c5;
    background: white;
    border-bottom: none;
    border-right: none;
  }

  &.disabled {
    color: #e0e0e0;
    cursor: not-allowed;
  }
`

// const TabPanel = styled(TabPanel)`
// display: flex !important;
// flex-direction: column;
// `

const TabPanel = styled(UnstyledTabPanel).attrs({
  selectedClassName: 'selected',
})`
  display: none;
  &.selected {
    display: block;
    width: 100%;
  }
`

Tab.tabsRole = 'Tab'
Tabs.tabsRole = 'Tabs'
TabPanel.tabsRole = 'TabPanel'
TabList.tabsRole = 'TabList'

export { Tab, TabList, Tabs, TabPanel }
