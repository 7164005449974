import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import SubstepTitle from '../flowsteps/SubstepTitle'
import Button from '../shared/Button'
import FadeIn from './FadeIn'

export default function GoalIntro({ advanceStep, stepKey }) {
  const parentVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.8, delayChildren: 2, delay: 1 },
    },
    closed: {
      opacity: 0,
      transition: { staggerChildren: 1, delayChildren: 2 },
    },
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <SubstepTitle title="Track opportunities" />

      <AnimatePresence>
        <motion.div variants={parentVariants} initial="closed" animate="open">
          <FadeIn>
            <p>
              Keep track of your applications
              <br />
              and interviews on the job board.
            </p>
          </FadeIn>

          <FadeIn>
            Use to briefcase button in the
            <br />
            upper left to toggle between this
            <br />
            view and your tracked opportunities.
          </FadeIn>

          <FadeIn>
            <Button onPress={() => advanceStep(stepKey)}>I Toggled</Button>
          </FadeIn>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
