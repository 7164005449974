import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 100, velocity: -500, duration: 0.5 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 100 },
    },
  },
}

export default function JobCard({ showNewJobModal }) {
  return (
    <>
      <CurvedArrow />
      <StyledWrapper onClick={showNewJobModal} variants={variants}>
        <StyledBody>
          <StyledCardTitle>
            <StyledCompanyName>Example Co.</StyledCompanyName>
            <div>Junior Developer</div>
          </StyledCardTitle>

          <Progress
            theme={{ success: { color: blue1, trailColor: lblue2 } }}
            percent={10}
            status="success"
          />
        </StyledBody>
      </StyledWrapper>
    </>
  )
}

// TODO: experimenting; standardize palette
const lblue3 = '#e4eff9'
const lblue2 = '#cadff3' // used as base at https://palx.jxnblk.com/
// const lblue1 = '#97bbdf'
const blue1 = '#6a98c5'
// const blue2 = '#4677A7'
// const blue3 = '#2d6193'
const gray3 = '#636d77'

const CurvedArrow = styled.div`
  position: absolute;
  right: -6px;
  top: 15px;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-right: 9px solid ${blue1};
  transform: rotate(70deg) scaleX(-1) scale(1.75);

  &:after {
    content: '';
    position: absolute;
    border: 0 solid transparent;
    border-top: 4px solid ${blue1};
    border-radius: 25px 7px 0 0;
    top: -11px;
    left: -19px;
    width: 26px;
    height: 16px;
    transform: rotate(23deg);
  }
`

const StyledWrapper = styled(motion.div)`
  margin: 18px auto;
  border: 3px dashed ${blue1};
  border-radius: 6px;
`

const StyledBody = styled.div`
  margin: 7px;
  opacity: 0.6;
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;
  transition: 300ms;

  &:hover {
    opacity: 0.9;
    box-shadow: 0 3px 0 #ccc;
    cursor: pointer;
  }
`

const StyledCardTitle = styled.div`
  padding: 12px;
  background-color: ${lblue3};
  color: ${gray3};
  border-radius: 6px 6px 0 0;
`

const StyledCompanyName = styled.div`
  font-weight: bold;
`
