import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth/reducer'
import errors from './errors/reducer'
import ui from './ui/reducer'

const rootReducer = history =>
  combineReducers({
    auth,
    errors,
    router: connectRouter(history),
    ui,
  })

export default rootReducer
