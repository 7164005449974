import React from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { motion } from 'framer-motion'
import { Progress } from 'react-sweet-progress'
import ReactTooltip from 'react-tooltip'
import { Tab, Tabs, TabList, TabPanel } from '../../shared/VerticalTabs'
import Components from '../stages'
import Contacts from './Contacts/'
import Notes from './Notes'
import Settings from './Settings'
import Button from '../../shared/Button'
import 'react-sweet-progress/lib/style.css'

const components = { ...Components }

const UPDATE_STAGE = gql`
  mutation UPDATE_STAGE($input: UpdateStageInput!) {
    updateStage(input: $input)
  }
`
const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 100, velocity: -500, duration: 0.5 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 100 },
    },
  },
}

export default function JobCard({ job, isModal, closeModal }) {
  const [updateStage] = useMutation(UPDATE_STAGE, {
    refetchQueries: ['QUERY_TRACKED_JOBS'],
    onComplete: () => ReactTooltip.rebuild(),
  })

  // Note: Modal clears job when closed
  if (!job) return null

  function advanceStage(stage) {
    updateStage({ variables: { input: { jobId: job.id, stage } } })
  }

  function undoStage() {
    updateStage({ variables: { input: { undo: true, jobId: job.id } } })
  }

  function calculateProgress() {
    // TODO: naive logic for prototype
    const numberOfStages = Object.keys(components).length
    const currentStageNumber = Object.keys(components).findIndex(
      s => s === job.stage
    )

    // finished stages:
    if (
      ['AcceptedOffer', 'DoneRejected', 'DeclinedOffer'].includes(job.stage)
    ) {
      return 100
    }

    // start with at least some progress:
    let progress = (currentStageNumber / numberOfStages) * 100 + 15
    if (progress > 100) progress = 100
    return progress
  }

  function renderJobStage(componentType) {
    const Component = components[componentType]
    return <Component job={job} advanceStage={stage => advanceStage(stage)} />
  }

  const historyToUndo = JSON.parse(job.stageHistory) || []

  function formattedJobUrl() {
    if (!job.url) return ''
    if (job.url !== '' && !job.url.startsWith('http'))
      return `http://${job.url}`
    return job.url
  }

  return (
    <StyledWrapper variants={variants} isModal={isModal}>
      <StyledLinkButton>
        {historyToUndo.length > 0 && (
          <span data-tip="Undo Step">
            <Button
              onPress={() => undoStage()}
              size="icon"
              margin={isModal && 'right'}
            >
              <i className="material-icons">undo</i>
            </Button>
          </span>
        )}
        {job.url && job.url !== '' && (
          <span data-tip="Open Job Listing">
            <Button
              onPress={() => window.open(formattedJobUrl(), '_blank')}
              size="icon"
              margin="right"
            >
              <i className="material-icons">link</i>
            </Button>
          </span>
        )}
        <span data-tip="Back to Job Board">
          <Button onPress={closeModal} size="icon">
            <i className="material-icons">exit_to_app</i>
          </Button>
        </span>
      </StyledLinkButton>

      <StyledCardTitle>
        <StyledCompanyName>{job.company.name}</StyledCompanyName>
        {job.title && <div>{job.title}</div>}
      </StyledCardTitle>

      <Progress
        theme={{ success: { color: blue1, trailColor: lblue2 } }}
        percent={calculateProgress()}
        status="success"
      />

      <div>
        <Tabs>
          <TabList>
            <Tab data-tip="Next Step" data-for="vertical-nav-tabs">
              <i className="material-icons">star</i>
            </Tab>
            {/*
            <Tab data-tip="Upcoming" data-for="vertical-nav-tabs">
              <i className="material-icons">event</i>
            </Tab>
            */}
            <Tab data-tip="Contacts" data-for="vertical-nav-tabs">
              <i className="material-icons">contacts</i>
            </Tab>
            <Tab data-tip="Notes" data-for="vertical-nav-tabs">
              <i className="material-icons">description</i>
            </Tab>
            <Tab data-tip="Settings" data-for="vertical-nav-tabs">
              <i className="material-icons">settings</i>
            </Tab>
          </TabList>

          <TabPanel>
            <StyledCardBody>
              {renderJobStage(job.stage || 'GetExcited')}
            </StyledCardBody>
          </TabPanel>
          {/*
          <TabPanel>
            <StyledCardBody>
              <p>[Upcoming]</p>
            </StyledCardBody>
          </TabPanel>
          */}
          <TabPanel>
            <StyledCardBody>
              <Contacts jobId={job.id} />
            </StyledCardBody>
          </TabPanel>
          <TabPanel>
            <StyledCardBody>
              <Notes {...job} />
            </StyledCardBody>
          </TabPanel>
          <TabPanel>
            <StyledCardBody>
              <Settings
                jobId={job.id}
                closeModal={closeModal}
                isModal={isModal}
                resetJob={() => advanceStage('GetExcited')}
              />
            </StyledCardBody>
          </TabPanel>
        </Tabs>
      </div>
    </StyledWrapper>
  )
}

// TODO: experimenting; standardize palette
const lblue3 = '#e4eff9'
const lblue2 = '#cadff3' // used as base at https://palx.jxnblk.com/
// const lblue1 = '#97bbdf'
const blue1 = '#6a98c5'
// const blue2 = '#4677A7'
// const blue3 = '#2d6193'
const gray3 = '#636d77'

const StyledWrapper = styled(motion.div)`
  margin-top: 12px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;

  ${({ isModal }) =>
    isModal &&
    css`
      margin-top: 0;
    `}
`

const StyledLinkButton = styled.div`
  float: right;
  margin: 9px;
`

const StyledCardTitle = styled.div`
  padding: 12px;
  background-color: ${lblue3};
  color: ${gray3};
  border-radius: 6px 6px 0 0;
`

const StyledCompanyName = styled.div`
  font-weight: bold;
`

const StyledCardBody = styled.div`
  padding: 12px;
`
