import React from 'react'
import styled from 'styled-components'

export default function EmphasizedText({ children }) {
  return <StyledText>{children}</StyledText>
}

const StyledText = styled.span`
  font-family: 'Dosis';
  font-weight: 700;
  display: inline-block;
  color: #777;
  transition: 2s ease-in;

  &:hover {
    color: #ff6b6b;
    // transform: translateY(-6px);
    transition: transform 0.8s;
  }
`
