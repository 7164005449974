import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'

export default class Tailor extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Tailor your resume" icon="👗" />

        <p>
          1) Tweak your summary. Make sure it makes sense for the position and
          industry you're applying for.
        </p>

        <p>
          2) Make sure your skills list includes the specific items they are
          looking for, if you have the experience.
        </p>
      </div>
    )
  }
}
