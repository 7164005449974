import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'
import ListItem from '../ListItem'

export default class OpenSource extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Contribute to open source" icon="💻" />

        <p>
          Much of our jobs as developers are made possible by open source
          software. Giving back to that community feels good and can help you
          stand out.
        </p>

        <p>
          Making your first contribution to a project can be intimidating, but
          the worst that can happen is that your pull request is rejected.
        </p>

        <p>Some tips for getting started:</p>

        <ul>
          <ListItem
            text="Find 'good first issue' labels"
            subtext="Many projects are looking to attract first-time contributors and use this label on their GitHub issues."
          />
          <ListItem
            text="Start with smaller projects"
            subtext="Larger projects may have dense context and few low-hanging fruit left."
          />
          <ListItem
            text="Publish your own library"
            subtext="Start small, e.g. React component, animation library, formatter utility."
          />
        </ul>
      </div>
    )
  }
}
