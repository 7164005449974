import React, { Component } from 'react'
import styled from 'styled-components'
import SubstepTitle from '../SubstepTitle'
import Button from '../../shared/Button'

export default class Shots extends Component {
  render() {
    const { showTracker } = this.props

    return (
      <div>
        <SubstepTitle title="Take some swings" icon="⚾️" />

        <p>Are there any companies you know you would like to apply to?</p>

        <p>Add a couple to the Job Tracker:</p>

        <StyledButtonWrapper>
          <Button onPress={showTracker}>Open Job Tracker</Button>
        </StyledButtonWrapper>
      </div>
    )
  }
}

const StyledButtonWrapper = styled.div`
  margin-top: 12px;
`
