import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { QUERY_USERS } from '../../gql/queries'
import { REMOVE_USER_FROM_ORG } from '../../gql/mutations'
import Login from '../Login'
import AdminCreateUser from './AdminCreateUser'
import AdminManageGroups from './AdminManageGroups'
import AdminManageJobs from './AdminManageJobs'
import AdminStats from './AdminStats'
import Button from '../shared/Button'

const SUPERADMIN_WHITELIST = [
  'adam@eventual.dev',
  'andrew@eventual.dev',
  'marc@eventual.dev',
]

export default function Admin() {
  const signedIn = useSelector(state => !!state.auth.userId)
  const email = useSelector(state => state.auth.email)
  const history = useHistory()
  const { data } = useQuery(QUERY_USERS)
  const [removeUserFromOrg] = useMutation(REMOVE_USER_FROM_ORG, {
    refetchQueries: ['QUERY_USERS'],
  })

  if (!signedIn) return <Login />
  if (!SUPERADMIN_WHITELIST.includes(email)) {
    history.push('/')
  }

  return (
    <StyledWrapper>
      <h1>Admin</h1>

      <Tabs>
        <TabList>
          <Tab>Users</Tab>
          <Tab>Jobs</Tab>
          <Tab>Groups</Tab>
          <Tab>Stats</Tab>
        </TabList>

        <TabPanel>
          <AdminCreateUser />
          <table className="eventual-table">
            <thead>
              <tr>
                <th>userId</th>
                <th>email</th>
                <th>job count</th>
                <th>organization(s)</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.users.map(user => {
                  return (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.email}</td>
                      <td>{user.jobs ? user.jobs.length : '0'}</td>
                      <td>
                        {user.orgs.map(org => (
                          <span key={org.name}>
                            {org.name} ({org.role || 'no role'})
                            <StyledButton
                              onPress={() =>
                                removeUserFromOrg({
                                  variables: {
                                    input: { userId: user.id, orgId: '1' },
                                  },
                                })
                              }
                              size="icon"
                              color="red"
                            >
                              <i className="material-icons">close</i>
                            </StyledButton>
                          </span>
                        ))}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </TabPanel>

        <TabPanel>
          <AdminManageJobs />
        </TabPanel>

        <TabPanel>
          <AdminManageGroups />
        </TabPanel>

        <TabPanel>
          <AdminStats />
        </TabPanel>
      </Tabs>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin: 24px;

  .react-tabs__tab--selected {
    background: #f9f9f9;
  }
`

const StyledButton = styled(Button)`
  transform: scale(0.55);
`
