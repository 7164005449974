import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { QUERY_JOBS } from '../../gql/queries'

export default function AdminManageJobs() {
  const { data } = useQuery(QUERY_JOBS)

  return (
    <StyledWrapper>
      <StyledTable className="eventual-table">
        <thead>
          <tr>
            <th>id</th>
            <th>comapny</th>
            <th>title</th>
            <th>url</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.jobs.map(job => {
              return (
                <tr key={job.id}>
                  <td>{job.id}</td>
                  <td>{job.company.name}</td>
                  <td>{job.title}</td>
                  <td>{job.url}</td>
                </tr>
              )
            })}
        </tbody>
      </StyledTable>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTable = styled.table`
  margin-top: 12px;
`
