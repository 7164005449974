import React from 'react'
import GoalTitle from '../GoalTitle'

export default function VolunteerReachOut() {
  return (
    <div>
      <GoalTitle title="Reach out" icon="looks_3" />

      <p>
        Once you've assessed what you can commit to, reach out to the organizers
        of the event you're interested in.
      </p>

      <p>
        If you're not sure how to approach them, start by letting them know
        you're intersted in getting more involved in the local tech community
        and ask if there's anything they could use a volunteer for. If you'd
        like to help in a specific way, ask if that's needed!
      </p>

      <p>
        Finally, when you make a commitment, do your absolute best to see it
        through. The impression you want to make with this new network is that
        you're reliable, friendly, and someone that they would want to work
        with. Make it so!
      </p>
    </div>
  )
}
