import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import SubstepTitle from '../flowsteps/SubstepTitle'
import Button from '../shared/Button'
import FadeIn from './FadeIn'

export default function GoalIntro({ advanceStep, stepKey }) {
  const parentVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.8, delayChildren: 2, delay: 1 },
    },
    closed: {
      opacity: 0,
      transition: { staggerChildren: 1, delayChildren: 2 },
    },
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <SubstepTitle title="Stand out" />

      <AnimatePresence>
        <motion.div variants={parentVariants} initial="closed" animate="open">
          <FadeIn>
            <p>Looking for ways to stand out from the crowd?</p>
          </FadeIn>

          <FadeIn>
            <span
              style={{
                display: 'inline-block',
                fontSize: 18,
                marginRight: 3,
              }}
              role="img"
              aria-label="sparkle-emoji"
            >
              🏆
            </span>
            Check out the <HighlightedText>Goals</HighlightedText> tab on the
            left.
            <span
              style={{
                display: 'inline-block',
                fontSize: 18,
                marginLeft: 3,
              }}
              role="img"
              aria-label="sparkle-emoji"
            >
              🏆
            </span>
          </FadeIn>

          <FadeIn>
            <Button onPress={() => advanceStep(stepKey)}>K</Button>
          </FadeIn>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

const HighlightedText = styled.span`
  font-family: 'Dosis';
  font-weight: 800;
  display: inline-block;
  color: #777;
  transition: 2s ease-in;

  &:hover {
    color: #ff6b6b;
    transform: translateY(-6px);
    transition: transform 0.8s;
  }
`
