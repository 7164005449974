import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'
import Button from '../../shared/Button'

export default class Tailor extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Automate your review" icon="🤖" />

        <p>
          Run your resume through CV Compiler. This service will catch many
          common resume shortcomings.
        </p>
        <Button onPress={() => window.open('https://cvcompiler.com', '_blank')}>
          Visit CV Compiler
        </Button>
      </div>
    )
  }
}
