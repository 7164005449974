import React from 'react'
import GoalTitle from '../GoalTitle'

export default function Write() {
  return (
    <div>
      <GoalTitle title="Write that post" icon="looks_5" />
      <p>
        Once you've got an outline, start to fill in the gaps. You can probably
        expect to make a few passes from start to finish.
      </p>
      <p>With each iteration, you should strive to:</p>
      <ul>
        <li>
          deliver the "just right" level of detail for your target audience,
        </li>
        <li>improve the transitions between sections, and</li>
        <li>prune away content that distracts from your core focus.</li>
      </ul>
    </div>
  )
}
