export const initialState = {
  activeFlowStep: 0,
  showLogin: true,
}

export default function ui(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, showLogin: false }
    case 'LOGOUT':
      return { ...state, showLogin: true }
    case 'UI:SET_ACTIVE_FLOWSTEP':
      return { ...state, activeFlowStep: action.payload.step }
    case 'UI:INCREMENT_ACTIVE_FLOWSTEP':
      return { ...state, activeFlowStep: state.activeFlowStep + 1 }
    default:
      return state
  }
}
