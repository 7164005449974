import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function ReceivedOffer({ advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        <EmphasizedText>You received an offer!</EmphasizedText>
      </StyledContent>

      <StyledContent>
        <span
          style={{ display: 'inline-block', marginRight: 3 }}
          role="img"
          aria-label="party-emoji"
        >
          🎊
        </span>
        Congrats! That's worth celebrating.
        <span
          style={{ display: 'inline-block', marginLeft: 3 }}
          role="img"
          aria-label="tada-emoji"
        >
          🎉
        </span>
      </StyledContent>

      <div>
        Once you've negotiated as best you can and discussed the opportunity
        with your people (e.g., partner, family, mentor, etc.), make that final
        decision.
      </div>

      <ButtonWrapper>
        <Button margin="right" onPress={() => advanceStage('DeclinedOffer')}>
          Decline Offer
        </Button>
        <Button onPress={() => advanceStage('AcceptedOffer')}>
          Accept Offer
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
