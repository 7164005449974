export function setActiveSteps(step, substep) {
  return { type: 'UI:SET_ACTIVE_STEPS', payload: { step, substep } }
}

export function setActiveFlowStep(step) {
  return { type: 'UI:SET_ACTIVE_FLOWSTEP', payload: { step } }
}

export function incrementFlowStep() {
  return { type: 'UI:INCREMENT_ACTIVE_FLOWSTEP' }
}
