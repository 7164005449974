import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { QUERY_SUGGESTED_JOBS } from '../../gql/queries'

export default function JobsTable() {
  const { data, loading, error } = useQuery(QUERY_SUGGESTED_JOBS)

  return (
    <StyledWrapper>
      <h2>Jobs Snapshot</h2>
      {loading && <div>~ Loading ~</div>}
      {error && <div>Something went wrong.</div>}

      <table className="eventual-table">
        <thead>
          <tr>
            <th>url</th>
            <th>company</th>
            <th>title</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.suggestedJobs.map(job => {
              return (
                <tr key={job.url}>
                  <td>{job.url}</td>
                  <td>{job.company.name}</td>
                  <td>{job.title}</td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin-bottom: 24px;
`
