import React from 'react'
import GoalTitle from '../GoalTitle'

export default function FirstMembers() {
  return (
    <div>
      <GoalTitle title="Gauge interest" icon="looks_two" />

      <p>
        Reach out to a few potential members of your new group to gauge their
        interest level. Where might you find these people? Are there any forums,
        chat servers, or events that they already frequent?
      </p>

      <p>
        If you find anyone especially excited by your idea, you may have just
        found yourself a co-founder! Having someone else to share the workload,
        and their network, could give your new community a great boost.
      </p>
    </div>
  )
}
