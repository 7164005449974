import React, { Component } from 'react'
import styled from 'styled-components'
import SubstepTitle from '../SubstepTitle'
import ReceivedOffer from '../../jobs/stages/ReceivedOffer'
import Button from '../../shared/Button'

export default class AcceptOffer extends Component {
  state = {
    jobs: [
      {
        title: 'Junior Developer',
        companyName: 'Wow Inc.',
        stage: 'ReceivedOffer',
      },
      {
        title: 'Software Developer',
        companyName: 'Reasonable Sounding Co.',
        stage: 'ReceivedOffer',
      },
    ],
  }

  advanceStage = index => {
    // TODO: handle via server
    const { jobs } = this.state
    const updatedJobs = jobs.slice()
    updatedJobs[index].stage = 'Accepted'
    this.setState({ jobs: updatedJobs })
  }

  renderList = () => {
    // TODO: fetch and show only jobs that are in the 'ReceivedOffer' stage
    const { jobs } = this.state

    let rows = []
    jobs.forEach((job, index) => {
      if (job.stage === 'ReceivedOffer') {
        rows.push(
          <ReceivedOffer
            key={index}
            advanceStage={() => this.advanceStage(index)}
          />
        )
      }
    })

    if (!rows.length) {
      return <div>Keep working!</div>
    }

    return (
      <div>
        <div>Ready to accept?</div>
        {rows}
      </div>
    )
  }

  render() {
    const { showTracker } = this.props

    return (
      <div>
        <SubstepTitle title="Bring home the bacon" icon="🥓" />
        <p>Use the Job Tracker to track offers.</p>
        <StyledButtonWrapper>
          <Button onPress={showTracker}>Open Job Tracker</Button>
        </StyledButtonWrapper>
      </div>
    )
  }
}

const StyledButtonWrapper = styled.div`
  margin-top: 12px;
`
