import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import styled from 'styled-components'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { QUERY_STEPS } from '../gql/queries'
import { COMPLETE_STEP } from '../gql/mutations'
import Header from './Header'
import Login from './Login'
import JobTracker from './jobs/tracker/JobTracker'
import StepContainer from './steps/StepContainer'
import stepData from '../stepData'
import { setActiveSteps } from '../store/ui/actions'

export default function Core() {
  const showLogin = useSelector(state => state.ui.showLogin)
  const activeStep = useSelector(state => state.ui.activeStep)
  const activeSubstep = useSelector(state => state.ui.activeSubstep)
  const dispatch = useDispatch()
  const store = useStore()
  const userId = store.getState().auth.userId
  const { data /*, loading, error*/ } = useQuery(QUERY_STEPS, {
    variables: { userId },
  })
  const [completeStep] = useMutation(COMPLETE_STEP, {
    refetchQueries: ['QUERY_STEPS'],
  })
  const [steps, updateStepData] = useState(stepData)
  const [showTracker, toggleShowTracker] = useState(false)

  useEffect(() => {
    if (data) {
      updateCompletions(steps, data.completedSteps)
      viewLatestStep()
    }
  }, [data])

  function updateCompletions(steps, completedSteps) {
    const completedCodes = completedSteps.map(cs => cs.code)
    const newSteps = steps.slice()

    // Mark each substep as complete
    newSteps.forEach((step, stepIndex) => {
      step.steps.forEach((substep, substepIndex) => {
        if (completedCodes.includes(substep.componentType)) {
          newSteps[stepIndex].steps[substepIndex].complete = true

          // If all substeps are complete, mark the parent as complete
          const substepStatuses = newSteps[stepIndex].steps.map(
            substep => substep.complete
          )
          if (!substepStatuses.includes(false)) {
            newSteps[stepIndex].complete = true
          }
        }
      })
    })

    updateStepData(newSteps)
  }

  function advanceStep(stepName) {
    if (stepName) completeStep({ variables: { input: { code: stepName } } })

    let newActiveSubstep = activeSubstep
    let newActiveStep = activeStep

    const isLastSubstep = steps[activeStep].steps.length === activeSubstep + 1

    if (isLastSubstep) {
      newActiveSubstep = 0
      newActiveStep = activeStep + 1
    } else {
      newActiveSubstep = activeSubstep + 1
    }

    setActive(newActiveStep, newActiveSubstep)
  }

  function setActive(activeStep, activeSubstep) {
    dispatch(setActiveSteps(activeStep, activeSubstep))
  }

  function viewLatestStep() {
    const stepIndex = steps.findIndex(step => !step.complete)

    // All steps are complete, set the last step & substep as active
    if (stepIndex === -1) {
      const lastStepIndex = steps.length - 1
      const lastStep = steps[lastStepIndex]
      const lastSubstepIndex = lastStep.steps.length - 1
      return setActive(lastStepIndex, lastSubstepIndex)
    }

    const substepIndex = steps[stepIndex].steps.findIndex(
      step => !step.complete
    )

    setActive(stepIndex, substepIndex)
  }

  return (
    <StyledAppContainer>
      <Header
        showTracker={() => toggleShowTracker(true)}
        viewLatestStep={viewLatestStep}
      />

      <StyledWrapper>
        {steps.map((step, index) => (
          <StepContainer
            {...step}
            key={step.title}
            setActive={setActive}
            active={activeStep === index}
            stepIndex={index}
            activeSubstep={activeSubstep}
            advanceStep={advanceStep}
            substeps={step.steps || []}
            showTracker={() => toggleShowTracker(true)}
          />
        ))}
      </StyledWrapper>

      <JobTracker show={showTracker} handleClose={() => toggleShowTracker()} />

      {showLogin && <Login />}
    </StyledAppContainer>
  )
}

const StyledAppContainer = styled.div`
  position: relative;
`

const StyledWrapper = styled.div`
  margin: 0 auto;
  max-width: 440px;
`
