import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import SubstepTitle from '../flowsteps/SubstepTitle'
import Button from '../shared/Button'
import FadeIn from './FadeIn'

export default function Learn({ advanceStep, stepKey }) {
  const parentVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.8, delayChildren: 2, delay: 1 },
    },
    closed: {
      opacity: 0,
      transition: { staggerChildren: 1, delayChildren: 2 },
    },
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <SubstepTitle title="Look to learn" />

      <AnimatePresence>
        <motion.div variants={parentVariants} initial="closed" animate="open">
          <FadeIn>
            <p>
              You can expect to get a<br />
              few <HighlightedText>no</HighlightedText>s before you get a{' '}
              <HighlightedText>yes</HighlightedText>.
            </p>
          </FadeIn>

          <FadeIn>
            <p>
              Every rejection will sting,{' '}
              <span
                style={{ display: 'inline-block', fontSize: 18, marginLeft: 3 }}
                role="img"
                aria-label="bee-emoji"
              >
                🐝
              </span>
              <br />
              but each is a learning opportunity.
            </p>
          </FadeIn>

          <FadeIn>
            <p>
              Take time to reflect on and
              <br />
              learn from each rejection.
            </p>
          </FadeIn>
          <FadeIn>
            <p>
              And remember:
              <br />
              every <HighlightedText>no</HighlightedText> is one step closer to
              a <HighlightedText>yes</HighlightedText>.
            </p>
          </FadeIn>

          <FadeIn>
            <Button onPress={() => advanceStep(stepKey)}>Heard</Button>
          </FadeIn>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

const HighlightedText = styled.span`
  font-family: 'Dosis';
  font-weight: 800;
  font-size: 18px;
  display: inline-block;
  color: #777;
  transition: 2s ease-in;

  &:hover {
    color: #ff6b6b;
    transform: translateY(-6px);
    transition: transform 0.8s;
  }
`
