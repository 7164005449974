import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'
import SimpleJobCard from './SimpleJobCard'
import CompletedState from './CompletedState'

export default class SubmitApplications extends Component {
  state = {
    jobs: [
      {
        title: 'Junior Developer',
        companyName: 'Wow Inc.',
        stage: 'NotYetSubmitted',
      },
      {
        title: 'Software Developer',
        companyName: 'Reasonable Sounding Co.',
        stage: 'NotYetSubmitted',
      },
    ],
  }

  advanceStage = index => {
    // TODO: handle via server
    const { jobs } = this.state
    const updatedJobs = jobs.slice()
    updatedJobs[index].stage = 'Interview'
    this.setState({ jobs: updatedJobs })
  }

  renderList = () => {
    // TODO: fetch and show only jobs that are in the 'NotYetSubmitted' stage
    const { showTracker } = this.props
    const { jobs } = this.state

    let rows = []
    jobs.forEach((job, index) => {
      if (job.stage === 'NotYetSubmitted') {
        rows.push(
          <SimpleJobCard
            key={index}
            job={job}
            advanceStage={() => this.advanceStage(index)}
          />
        )
      }
    })

    if (!rows.length) {
      return <CompletedState showTracker={showTracker} />
    }

    return (
      <div>
        <div>Get those applications in!</div>
        {rows}
      </div>
    )
  }

  render() {
    return (
      <div>
        <SubstepTitle title="Submit applications" icon="📨" />
        {this.renderList()}
      </div>
    )
  }
}
