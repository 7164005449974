import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import { QUERY_TRACKED_JOB_CONTACTS } from '../../../../gql/queries'
import {
  UPDATE_CONTACT_ACTIVITY,
  DELETE_CONTACT_ACTIVITY,
} from '../../../../gql/mutations'
import Button from '../../../shared/Button'

export default function EditActivityForm({
  activity,
  jobId,
  contactId,
  closeForm,
}) {
  const [date, setDate] = useState(moment(activity.date, 'x'))
  const [focused, setFocused] = useState(false)
  const [medium, setMedium] = useState(activity.medium)
  const [learned, setLearned] = useState(activity.learned)
  const [nextSteps, setNextSteps] = useState(activity.nextSteps)
  const [updateActivity] = useMutation(UPDATE_CONTACT_ACTIVITY, {
    refetchQueries: [
      {
        query: QUERY_TRACKED_JOB_CONTACTS,
        variables: { input: { jobId } },
      },
    ],
  })
  const [deleteActivity] = useMutation(DELETE_CONTACT_ACTIVITY, {
    refetchQueries: [
      {
        query: QUERY_TRACKED_JOB_CONTACTS,
        variables: { input: { jobId } },
      },
    ],
  })

  function handleCreateActivity() {
    updateActivity({
      variables: {
        input: {
          activityId: activity.id,
          jobId,
          contactId,
          date,
          medium,
          learned,
          nextSteps,
        },
      },
    })
    closeForm()
  }

  function handleDeleteActivity() {
    deleteActivity({ variables: { input: { activityId: activity.id } } })
    closeForm()
  }

  return (
    <StyledContactForm>
      <StyledContactFormHeader>
        <StyledTitle>Edit your interaction recap:</StyledTitle>
        <StyledBackButton size="icon" onPress={closeForm}>
          <i style={{ fontSize: '32px' }} className="material-icons">
            arrow_back
          </i>
        </StyledBackButton>
      </StyledContactFormHeader>
      <StyledContactFormBody>
        <div>Date:</div>
        <SingleDatePicker
          id="new-activity-date"
          date={date}
          onDateChange={setDate}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={() => false}
          block
        />
        <StyledTitleAfterPicker>Medium:</StyledTitleAfterPicker>
        <StyledInput
          value={medium}
          onChange={e => setMedium(e.target.value)}
          placeholder="Medium (e.g., Zoom, Slack, in-person)"
          type="text"
        />
        <div>What you learned:</div>
        <StyledTextArea
          value={learned}
          onChange={e => setLearned(e.target.value)}
          placeholder="What you learned"
          type="text"
        />
        <div>Next steps:</div>
        <StyledTextArea
          value={nextSteps}
          onChange={e => setNextSteps(e.target.value)}
          placeholder="Next steps"
          type="text"
        />
        <Button disabled={!medium} onPress={handleCreateActivity}>
          Update Interaction
        </Button>
        <StyledDeleteButton color="red" onPress={handleDeleteActivity}>
          Delete Interaction
        </StyledDeleteButton>
      </StyledContactFormBody>
    </StyledContactForm>
  )
}

const StyledContactForm = styled.div`
  display: flex;
  flex-direction: column;
  background: #eee;
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;
  padding: 12px;
`

const StyledContactFormHeader = styled.div`
  position: relative;
`

const StyledContactFormBody = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledBackButton = styled(Button)`
  position: absolute;
  right: -8px;
  top: -12px;
  transform: scale(0.55);
`

const StyledTitle = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`

const StyledTitleAfterPicker = styled.div`
  margin-top: 12px;
`

const StyledInput = styled.input`
  display: block;
  margin-bottom: 12px;
`

const StyledTextArea = styled.textarea`
  display: block;
  margin-bottom: 12px;
`

const StyledDeleteButton = styled(Button)`
  margin-top: 12px !important;
`
