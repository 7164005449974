import React from 'react'
import GoalTitle from '../GoalTitle'

export default function Medium() {
  return (
    <div>
      <GoalTitle title="Select a hosting method" icon="looks_one" />

      <p>Where will your words will live?</p>

      <p>
        <b>If you want to focus solely on writing,</b> a platform like{' '}
        <a href="https://medium.com">Medium</a> is a good option. Pros: instant
        setup, a nice editor that supports code snippets, and a built-in
        distribution network.
      </p>
      <p>
        <b>If you want an addition for your portfolio,</b> building a custom
        blog site is another option. Pros: personalized features, a chance to
        learn a new technology, e.g.,{' '}
        <a href="https://www.gatsbyjs.com/">Gatsby</a>,{' '}
        <a href="https://wordpress.com/">Wordpress</a>.
      </p>
    </div>
  )
}
