import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default class GoalTitle extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
  }

  render() {
    const { title, icon } = this.props

    return (
      <StyledTitle>
        {icon && (
          <i
            style={{ fontSize: '24px', marginRight: '6px' }}
            className="material-icons"
          >
            {icon}
          </i>
        )}
        {` ${title}`}
      </StyledTitle>
    )
  }
}

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Raleway', sans serif;
  color: #6a98c5;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: bold;
`
