import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { QUERY_TRACKED_JOB_CONTACTS } from '../../../gql/queries'
import { CREATE_CONTACT } from '../../../gql/mutations'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'

export default function NetworkAddContact({ job, advanceStage }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [notes, setNotes] = useState('')
  const [createContact] = useMutation(CREATE_CONTACT, {
    refetchQueries: [
      {
        query: QUERY_TRACKED_JOB_CONTACTS,
        variables: { input: { jobId: job.id } },
      },
    ],
  })

  function handleCreateContact() {
    createContact({
      variables: { input: { jobId: job.id, name, email, notes } },
    })
    advanceStage('NetworkContactOnboarding')
  }

  return (
    <StyledWrapper>
      <StyledContent>
        Great! Your chances are much better just by having a potential
        connection to the company.
      </StyledContent>

      <StyledContent>
        You can use Eventual to keep track of who you interact with at each
        company. <EmphasizedText>Add your first contact</EmphasizedText> now:
      </StyledContent>

      <StyledContactForm>
        <StyledContactFormBody>
          <input
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Name"
            type="text"
          />
          <StyledInput
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email (Optional)"
            type="text"
          />
          <StyledTextArea
            value={notes}
            onChange={e => setNotes(e.target.value)}
            placeholder="Notes (Optional)"
            type="text"
          />
          <StyledSubmitButton disabled={!name} onPress={handleCreateContact}>
            Add Contact
          </StyledSubmitButton>
        </StyledContactFormBody>
      </StyledContactForm>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`

const StyledContactForm = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 4px #ccc;
  padding: 12px;
  border: 3px dashed #ccc;
  border-radius: 6px;
`

const StyledContactFormBody = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInput = styled.input`
  display: block;
  margin-top: 12px;
`

const StyledTextArea = styled.textarea`
  display: block;
  margin-top: 12px;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px !important;
`
