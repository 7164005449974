import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import Resources from './Resources'

export default function CoverLetter({ job, advanceStage }) {
  return (
    <StyledWrapper>
      <div>
        Unless you're asked not to write one, a{' '}
        <EmphasizedText>cover letter</EmphasizedText> is always a good idea to
        include in an application. Here's a simple template:
      </div>

      <ul>
        <li style={{ marginBottom: '12px' }}>
          <b>Paragraph 1:</b> Convey your excitement about applying for this
          position and include how you came across the opportunity.
        </li>
        <li style={{ marginBottom: '12px' }}>
          <b>Paragraph 2:</b> Explain why you're uniquely qualified to enhance
          their team.
        </li>
        <li>
          <b>Paragraph 3:</b> Explain why you're especially interested in this
          company and/or this industry.
        </li>
      </ul>

      <Resources>
        <li>
          Turing School's{' '}
          <a
            href="https://github.com/turingschool/career-development-curriculum/blob/master/module_four/cover_letter_checklist.md"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cover Letter Checklist
          </a>
        </li>
        <li>
          Ian Douglas's{' '}
          <a
            href="https://techinterview.guide/section-2-preparation/chapter-8-cover-letters/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cover Letter notes
          </a>
        </li>
      </Resources>

      <StyledButtonWrapper>
        <Button onPress={() => advanceStage('NotYetSubmitted')}>
          Cover Letter Written
        </Button>
      </StyledButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
`
