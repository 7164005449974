import React from 'react'
import GoalTitle from '../GoalTitle'

export default function ResearchMeetups() {
  return (
    <div>
      <GoalTitle title="Research Meetups" icon="looks_one" />

      <p>
        Meetup organizers have a long list of responsibilities: scheduling
        events, sourcing talks, interacting with sponsors, creating content,
        ordering food and drink, and other logistics.
      </p>

      <p>
        It's a lot of work and help is usually appreciated to grow and foster
        the community. Find user groups in your area that you might want to get
        involved with. Ideally, the attendees or sponsors of these events
        represent companies you might like to work for.
      </p>

      <p>
        Working with Meetup groups is a great way to expand your network with
        local attendees, co-organizers, hiring companies, and speakers.
      </p>
    </div>
  )
}
