import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'
import ListItem from '../ListItem'

export default class Organize extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Be a community organizer" icon="🍕" />

        <p>
          Being an event organizer can be the quickest route to building a
          network. A few quick tips to get started:
        </p>

        <ul>
          <ListItem
            text="Help out at a meetup group"
            subtext="Reach out to the organizers of your favorite meetup groups and ask if they could use any help with logistics or finding speakers."
          />
          <ListItem
            text="Volunteer at a conference"
            subtext="This could be a great way to meet speakers, organizers, and attendees."
          />
          <ListItem
            text="Start a meetup group"
            subtext="If no group exists for a topic you're passionate about, try to get one off the ground."
          />
        </ul>
      </div>
    )
  }
}
