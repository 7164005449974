export const initialState = {
  email: null,
  userId: null,
  role: null,
  expiredToken: false,
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        email: action.payload.email,
        userId: action.payload.id,
        role: action.payload.role,
        expiredToken: false,
      }
    case 'LOGOUT':
      return {
        ...initialState,
        expiredToken: action.payload ? action.payload.expiredToken : false,
      }
    default:
      return state
  }
}
