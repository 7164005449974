import React, { Component } from 'react'
import styled from 'styled-components'
import SubstepTitle from '../SubstepTitle'

export default class SkillList extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Write Thank You notes" icon="📝" />

        <p>
          No matter how well you think you did, write at least one{' '}
          <StyledText>thank you</StyledText> note to the interviewing team.
        </p>

        <p>
          How many you write is up to you, but take a moment to thank the
          interviewers for pausing their day(s) to give you a shot.
        </p>
      </div>
    )
  }
}

const StyledText = styled.span`
  font-family: 'Dosis';
`
