import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { logout } from '../../store/auth/actions'

function Header({ viewLatestStep }) {
  const dispatch = useDispatch()
  const signedIn = useSelector(state => !!state.auth.userId)

  return (
    <StyledHeader>
      <StyledHeaderContent>
        <span onClick={viewLatestStep}>
          <HeaderLetter>e</HeaderLetter>
          <HeaderLetter>v</HeaderLetter>
          <HeaderLetter>e</HeaderLetter>
          <HeaderLetter>n</HeaderLetter>
          <HeaderLetter>t</HeaderLetter>
          <HeaderLetter>u</HeaderLetter>
          <HeaderLetter>a</HeaderLetter>
          <HeaderLetter>l</HeaderLetter>
        </span>

        <StyledRightButtonWrapper>
          {signedIn && (
            <StyledLinkButton onClick={() => dispatch(logout())}>
              log out
            </StyledLinkButton>
          )}
        </StyledRightButtonWrapper>
      </StyledHeaderContent>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  font-family: 'Dosis';
  font-weight: 700;
  font-size: 28px;
  background-color: white;
  color: #777;
  padding: 12px;
  text-align: center;
  font-weight: 900;
  box-shadow: 0 3px 4px #ccc;
  z-index: 9;
`

const StyledHeaderContent = styled.div`
  position: relative;
  max-width: 440px;
  margin: 0 auto;
`

const StyledRightButtonWrapper = styled.div`
  position: absolute;
  right: 4px;
  top: -4px;
  z-index: 999;
`

const HeaderLetter = styled.span`
  display: inline-block;
  transition: 2s ease-in;

  &:hover {
    color: #ff6b6b;
    transform: translateY(-8px);
    transition: transform 0.8s;
  }
`

const StyledLinkButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 12px;
`
