import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Button from './shared/Button'
import Logo from './shared/Logo'
import { logout } from '../store/auth/actions'

function Header({ viewLatestStep }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const signedIn = useSelector(state => !!state.auth.userId)

  function handleShowTracker() {
    history.location.pathname === '/tracker'
      ? history.push('/')
      : history.push('/tracker')
  }

  return (
    <StyledHeader>
      <StyledHeaderContent>
        <StyledLeftButtonWrapper>
          <Button onPress={handleShowTracker} size="icon">
            <i className="material-icons">
              {history.location.pathname === '/tracker'
                ? 'home'
                : 'card_travel'}
            </i>
          </Button>
        </StyledLeftButtonWrapper>

        <span onClick={viewLatestStep}>
          <Logo />
        </span>

        <StyledRightButtonWrapper>
          {signedIn && (
            <StyledLinkButton onClick={() => dispatch(logout())}>
              log out
            </StyledLinkButton>
          )}
        </StyledRightButtonWrapper>
      </StyledHeaderContent>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  background-color: white;
  color: #777;
  padding: 12px;
  text-align: center;
  box-shadow: 0 3px 4px #ccc;
  z-index: 9;
`

const StyledHeaderContent = styled.div`
  position: relative;
  max-width: 440px;
  margin: 0 auto;
`

const StyledLeftButtonWrapper = styled.div`
  position: absolute;
  left: 4px;
  top: -4px;
  z-index: 999;
`

const StyledRightButtonWrapper = styled.div`
  position: absolute;
  right: 4px;
  top: 0;
  z-index: 999;
`

const StyledLinkButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 12px;
`
