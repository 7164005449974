import React from 'react'
import GoalTitle from '../GoalTitle'

export default function ResearchConferences() {
  return (
    <div>
      <GoalTitle title="Research conferences" icon="looks_two" />

      <p>
        Compared to Meetup events, conferences are much larger productions and
        typically only occur only once a year. Between reviewing talk
        submissions, website design, checking in attendees, and a thousand other
        large and small tasks, there are plenty of opporunities to help out
        prior to or during the event.
      </p>

      <p>
        Conferences usually have their own tight-knit communities of organizers,
        speakers, sponsors, and attendees from reputable companies that share an
        interest in the conference topic. Volunteering is a great way to gain
        access to that network.
      </p>

      <p>
        Make a list of conferences in your region that you'd be interested in
        volunteering for. Be mindful of the type of commitment you're willing to
        make; volunteering during an event may take just a few hours over a
        couple days, but reviewing talk proposals may require many hours over
        several weeks.
      </p>
    </div>
  )
}
