import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import { QUERY_TRACKED_JOB_CONTACTS } from '../../../../gql/queries'
import { CREATE_CONTACT_ACTIVITY } from '../../../../gql/mutations'
import Button from '../../../shared/Button'

export default function NewActivityForm({ jobId, contactId, closeForm }) {
  const [date, setDate] = useState(moment())
  const [focused, setFocused] = useState(false)
  const [medium, setMedium] = useState('')
  const [learned, setLearned] = useState('')
  const [nextSteps, setNextSteps] = useState('')
  const [createActivity] = useMutation(CREATE_CONTACT_ACTIVITY, {
    refetchQueries: [
      {
        query: QUERY_TRACKED_JOB_CONTACTS,
        variables: { input: { jobId } },
      },
    ],
  })

  function handleCreateActivity() {
    createActivity({
      variables: {
        input: {
          jobId,
          contactId,
          date,
          medium,
          learned,
          nextSteps,
        },
      },
    })
    closeForm()
  }

  return (
    <StyledContactForm>
      <StyledContactFormHeader>
        <StyledTitle>Interaction recap:</StyledTitle>
        <StyledBackButton size="icon" onPress={closeForm}>
          <i style={{ fontSize: '32px' }} className="material-icons">
            arrow_back
          </i>
        </StyledBackButton>
      </StyledContactFormHeader>
      <StyledContactFormBody>
        <SingleDatePicker
          id="new-activity-date"
          date={date}
          onDateChange={setDate}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={() => false}
          block
        />
        <StyledInput
          value={medium}
          onChange={e => setMedium(e.target.value)}
          placeholder="Medium (e.g., Zoom, Slack, in-person)"
          type="text"
        />
        <StyledTextArea
          value={learned}
          onChange={e => setLearned(e.target.value)}
          placeholder="What you learned"
          type="text"
        />
        <StyledTextArea
          value={nextSteps}
          onChange={e => setNextSteps(e.target.value)}
          placeholder="Next steps"
          type="text"
        />
        <StyledSubmitButton disabled={!medium} onPress={handleCreateActivity}>
          Log Activity
        </StyledSubmitButton>
      </StyledContactFormBody>
    </StyledContactForm>
  )
}

const StyledContactForm = styled.div`
  display: flex;
  flex-direction: column;
  background: #eee;
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;
  padding: 12px;
  margin-top: 12px;
`

const StyledContactFormHeader = styled.div`
  position: relative;
`

const StyledContactFormBody = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledBackButton = styled(Button)`
  position: absolute;
  right: -8px;
  top: -12px;
  transform: scale(0.55);
`

const StyledTitle = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
`

const StyledInput = styled.input`
  display: block;
  margin-top: 12px;
`

const StyledTextArea = styled.textarea`
  display: block;
  margin-top: 12px;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px !important;
`
