import React, { Component } from 'react'
import styled from 'styled-components'
import SubstepTitle from '../SubstepTitle'

export default class Learn extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Look to learn" icon="🧐" />

        <p>
          Chances are you'll get a few <StyledAnswer>no</StyledAnswer>s before
          you get a <StyledAnswer>yes</StyledAnswer>.
        </p>

        <p>
          Learn to treat each <StyledAnswer>no</StyledAnswer> as a learning
          opportunity that gets you one step closer to a{' '}
          <StyledAnswer>yes</StyledAnswer>.
        </p>

        <p>
          Take the time for a brief retrospective after each successful or
          unsuccessful interaction and look for opportunities to improve.
        </p>
      </div>
    )
  }
}

const StyledAnswer = styled.span`
  font-family: 'Dosis';
`
