import React from 'react'
import GoalTitle from '../GoalTitle'

export default function BrainstormSponsors() {
  return (
    <div>
      <GoalTitle title="Brainstorm sponsors" icon="looks_3" />

      <p>
        Sponsors are by no means a requirement, but people like pizza, and pizza
        costs money.
      </p>

      <p>
        Potential costs you could run into: food, drinks, location fee, Meetup
        group fee, web hosting, and so on.
      </p>

      <p>
        Are there any companies that come to mind that might like to support
        your community 1) financially, 2) with their product or service, or 3)
        by offering their office as a meeting location?
      </p>
    </div>
  )
}
