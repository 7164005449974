import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import { UPDATE_USER_JOB } from '../../../gql/mutations'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function GetExcited({ job, advanceStage }) {
  const [text, setText] = useState(job.excited || '')
  const [updateUserJob, { loading, error }] = useMutation(UPDATE_USER_JOB, {
    onCompleted: () => advanceStage('NetworkIntro'),
  })

  function handleSubmit() {
    updateUserJob({
      variables: {
        input: { jobId: job.id, notes: job.notes || '', excited: text },
      },
    })
  }

  return (
    <StyledWrapper>
      <StyledContent>
        What about this job are you <EmphasizedText>excited</EmphasizedText>{' '}
        about?
      </StyledContent>
      <StyledContent>
        Put another way: if nothing about this gig interests you, do you expect
        the interviewers to be interested in you?
      </StyledContent>
      <StyledContent>
        Before you go any further, find something you might look forward to
        about joining this company.
      </StyledContent>
      <div>
        This may be related to:
        <ul>
          <li>the product or service you would be working on;</li>
          <li>the team or culture you would be working within;</li>
          <li>the industry you'd be working in;</li>
          <li>a personal mission you'd be serving.</li>
        </ul>
      </div>
      <StyledTextArea
        placeholder="I'm excited about..."
        onChange={e => setText(e.target.value)}
        value={text}
      />

      <ButtonWrapper>
        <Button disabled={!text || loading} onPress={handleSubmit}>
          Submit
        </Button>
      </ButtonWrapper>

      {loading && <p>Loading...</p>}
      {error && <p>Whoops! Something went wrong.</p>}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`

const StyledTextArea = styled.textarea`
  display: block;
  margin-top: 12px;
`
