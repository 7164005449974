import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'
import Resources from './Resources'

export default function RoundTwoInterviewPhoneScreen({ advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        Great - you have a <EmphasizedText>phone screen</EmphasizedText> coming
        up!
        <span
          style={{ display: 'inline-block', marginLeft: 5 }}
          role="img"
          aria-label="phone-emoji"
        >
          📞
        </span>
      </StyledContent>
      <StyledContent>
        You may get a few technical questions, but often these calls are mainly
        a chance to get to know you and to get an idea if you'd be a good
        addition to the team.
      </StyledContent>
      <StyledContent>
        Remember to smile and be ready to convey your reason for being excited
        about the opportunity!
      </StyledContent>

      <Resources>
        <li>
          The Technical Interviewing Prep book's{' '}
          <a
            href="https://techinterview.guide/section-3-interviewing/chapter-10-interviews/typical-workflow/2-phone-screen/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Phone Screen chapter
          </a>
          .
        </li>
        <li>
          Turing School's{' '}
          <a
            href="https://github.com/turingschool/career-development-curriculum/blob/master/module_four/interview_prep_resources.md"
            target="_blank"
            rel="noopener noreferrer"
          >
            Interview Prep Resources
          </a>
        </li>
      </Resources>

      <ButtonWrapper>
        <Button onPress={() => advanceStage('RoundTwoThankYouPhoneScreen')}>
          Completed Interview
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
