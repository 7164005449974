import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import StepContainer from './StepContainer'

export default function GoalDetail({ goal, viewGoalList }) {
  const [activeStep, setActiveStep] = useState(0)

  return (
    <StyledWrapper>
      <StyledButton size="icon" onPress={viewGoalList}>
        <i style={{ fontSize: '28px' }} className="material-icons">
          arrow_back
        </i>
      </StyledButton>
      <StyledTitle>{goal.title}</StyledTitle>
      <div>{goal.description}</div>

      {goal.steps.map((step, index) => (
        <StepContainer
          {...step}
          key={step.title}
          setActive={setActiveStep}
          active={activeStep === index}
          stepIndex={index}
          // advanceStep={advanceStep}
        />
      ))}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding: 0 24px 24px;
  position: relative;
`

const StyledTitle = styled.h2`
  text-align: center;
`

const StyledButton = styled(Button)`
  position: absolute;
  top: -8px;
  left: 12px;
  transform: scale(0.6);
  margin-left: -5px;
`
