import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import SubstepTitle from '../flowsteps/SubstepTitle'
import Button from '../shared/Button'
import FadeIn from './FadeIn'

export default function Final() {
  const history = useHistory()

  const parentVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.8, delayChildren: 2, delay: 1 },
    },
    closed: {
      opacity: 0,
      transition: { staggerChildren: 2, delayChildren: 2, delay: 1 },
    },
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <SubstepTitle title="" icon="🏁" />

      <AnimatePresence>
        <motion.div variants={parentVariants} initial="closed" animate="open">
          <FadeIn>You're doing great!</FadeIn>

          <FadeIn>
            Just keep putting one foot in front
            <br />
            of the other and keep a steady pace.
          </FadeIn>

          <FadeIn>Your next step awaits you in the job tracker:</FadeIn>

          <FadeIn>
            <Button onPress={() => history.push('/tracker')}>
              Open Job Tracker
            </Button>
          </FadeIn>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
