import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_SUGGESTED_JOB } from '../../gql/mutations'
import Button from '../shared/Button'

export default function CreateSuggestedJob() {
  const [company, setCompany] = useState('')
  const [url, setUrl] = useState('')
  const [title, setTitle] = useState('')
  const [createSuggestedJob /*, { data, loading, error }*/] = useMutation(
    CREATE_SUGGESTED_JOB,
    {
      refetchQueries: ['QUERY_SUGGESTED_JOBS'],
    }
  )
  // console.log('∆∆∆ createJob data', data)
  // console.log('∆∆∆ createJob loading', loading)
  // console.log('∆∆∆ createJob error', error)

  function resetForm() {
    setCompany('')
    setUrl('')
    setTitle('')
  }

  function handleClick() {
    const job = {
      company,
      url,
      title,
    }
    createSuggestedJob({ variables: { input: { ...job } } })
    resetForm()
  }

  return (
    <StyledWrapper>
      <FormWrapper>
        <input
          value={company}
          onChange={e => setCompany(e.target.value)}
          placeholder="Company"
          type="text"
        />
        <br />
        <StyledInput
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Title"
          type="text"
        />
        <br />
        <StyledInput
          value={url}
          onChange={e => setUrl(e.target.value)}
          placeholder="URL of job listing"
          type="text"
        />
        <StyledSubmitButton
          type="secondary"
          disabled={!company}
          onPress={handleClick}
        >
          Suggest Job
        </StyledSubmitButton>
      </FormWrapper>
      <Description>
        Jobs you enter here will be displayed to students as{' '}
        <i>Suggested Jobs</i>.
        <br />
        <br />
        This page will display data about the number of students tracking this
        job and their progress.
      </Description>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  margin-bottom: 24px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;
  padding: 18px;
  max-width: 640px;
`

const StyledInput = styled.input`
  margin-top: 12px;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px !important;
`

const FormWrapper = styled.div`
  min-width: 240px;
  max-width: 440px;
  display: flex;
  flex-direction: column;
  margin-right: 18px;
`

const Description = styled.div`
  padding: 12px;
  background-color: #eceeef;
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;
`
