import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { QUERY_ORG_GROUPS } from '../../gql/queries'

export default function MemberList({ members }) {
  const { data: groupData } = useQuery(QUERY_ORG_GROUPS)

  if (!members) {
    return <div>Whoops! Something wen't wrong while fetching members.</div>
  }

  let groupOptions = []
  if (groupData && groupData.groups) {
    groupOptions = groupData.groups.map(group => ({
      label: group.name,
      value: group.id,
    }))
  }
  groupOptions.unshift({ value: '', label: '(None)' })

  function formatGroups(groups) {
    if (groups.length === 0) return <i>(none)</i>
    return groups.map(group => group.name).join(', ')
  }

  return (
    <StyledWrapper>
      <table className="eventual-table">
        <thead>
          <tr>
            <th>name</th>
            <th>email</th>
            <th>role</th>
            <th>groups</th>
          </tr>
        </thead>
        <tbody>
          {members.map(member => {
            return (
              <tr key={member.id}>
                <td>{member.name}</td>
                <td>{member.email}</td>
                <td>{member.role.name}</td>
                <td>{formatGroups(member.orgGroups)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  // margin: 0 24px 12px;
`
