import React, { Component } from 'react'
import styled from 'styled-components'
import SubstepTitle from '../SubstepTitle'
import Button from '../../shared/Button'

export default class Moonshots extends Component {
  render() {
    const { showTracker } = this.props

    return (
      <div>
        <SubstepTitle title="Add moonshots" icon="🌙" />

        <p>
          Add at least one company that you would really love to work for, even
          if its a long shot. You might surprise yourself!
        </p>

        <StyledButtonWrapper>
          <Button onPress={showTracker}>Open Job Tracker</Button>
        </StyledButtonWrapper>
      </div>
    )
  }
}

const StyledButtonWrapper = styled.div`
  margin-top: 12px;
`
