import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function RoundTwoResults({ advanceStage }) {
  return (
    <StyledWrapper>
      <div>
        Your part is done here for now, but this is a good opportunity to pause
        and reflect on your experience so far before moving on to your next
        application.
      </div>

      <ul>
        <li>Are you proud of something you did and want to repeat it?</li>
        <li>Did you identify any knowledge gaps you want to brush up on?</li>
        <li>What seemed most important to the interviewer?</li>
      </ul>

      <div>
        When the interview <EmphasizedText>results</EmphasizedText> come back,
        move on to the next step:
      </div>

      <ButtonWrapper>
        <Button margin="right" onPress={() => advanceStage('Retrospective')}>
          Rejected
        </Button>
        <Button
          margin="right"
          onPress={() => advanceStage('RoundThreeInterviewType')}
        >
          Next Round
        </Button>
        <Button onPress={() => advanceStage('ReceivedOffer')}>
          Received Offer
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
