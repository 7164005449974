import gql from 'graphql-tag'

export const QUERY_ORGS = gql`
  query QUERY_ORGS {
    orgs {
      id
      name
    }
  }
`

export const QUERY_USER_ORG_GROUPS = gql`
  query QUERY_USER_ORG_GROUPS($input: UserOrgGroupsInput!) {
    userOrgGroups(input: $input) {
      id
      name
    }
  }
`

export const QUERY_ORG_GROUPS = gql`
  query QUERY_ORG_GROUPS {
    orgGroups {
      id
      name
    }
  }
`

export const QUERY_USER_SNAPSHOT = gql`
  query QUERY_USER_SNAPSHOT {
    userSnapshot {
      id
      name
      email
      role {
        id
        name
      }
      orgGroups {
        id
        name
      }
      completedStepCount
      watching
      appliedTo
      interviews
      rejections
      offers
      accepted
    }
  }
`

export const QUERY_ROLES = gql`
  query QUERY_ROLES {
    roles {
      id
      name
    }
  }
`

export const QUERY_JOBS = gql`
  query QUERY_JOBS {
    jobs {
      id
      company {
        id
        name
      }
      url
      title
    }
  }
`

export const QUERY_USERS_STATS = gql`
  query QUERY_USERS_STATS($days: Int) {
    usersStats(days: $days) {
      data {
        name
        data
      }
    }
  }
`

export const QUERY_TRACKED_JOBS_STATS = gql`
  query QUERY_TRACKED_JOBS_STATS($days: Int) {
    trackedJobsStats(days: $days) {
      data {
        name
        data
      }
    }
  }
`

export const QUERY_TRACKED_JOBS = gql`
  query QUERY_TRACKED_JOBS {
    trackedJobs {
      id
      company {
        id
        name
      }
      url
      title
      stage
      stageHistory
      notes
      excited
    }
  }
`

export const QUERY_TRACKED_JOB_CONTACTS = gql`
  query QUERY_TRACKED_JOB_CONTACTS($input: TrackedJobContactsInput!) {
    trackedJobContacts(input: $input) {
      id
      name
      email
      notes
      contactActivities {
        id
        date
        medium
        learned
        nextSteps
      }
    }
  }
`

export const QUERY_SUGGESTED_JOBS = gql`
  query QUERY_SUGGESTED_JOBS {
    suggestedJobs {
      id
      company {
        id
        name
      }
      url
      title
    }
  }
`

export const QUERY_USERS = gql`
  query QUERY_USERS {
    users {
      id
      email
      jobs {
        id
      }
      orgs {
        name
        role
      }
    }
  }
`

export const QUERY_STEPS = gql`
  query QUERY_STEPS {
    completedSteps {
      id
      code
    }
  }
`
