import React from 'react'
import GoalTitle from '../GoalTitle'

export default function WriteTalk() {
  return (
    <div>
      <GoalTitle title="Write" icon="looks_3" />

      <p>
        Lightning talks are only five to ten minutes. That's typically only
        enough time to make one point really well. What is the one takeaway you
        want your audience to remember?
      </p>

      <p>
        Once you've identified the main point, work your way back to the
        beginning. Is there context you need to establish? Any common
        counterpoints you want to address?
      </p>

      <p>
        If you create slides, keep it to just a few and limit the nunmber of
        words on them. If your audience gets distracted, they'll miss half the
        talk.
      </p>
    </div>
  )
}
