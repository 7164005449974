import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'
import { QUERY_TRACKED_JOB_CONTACTS } from '../../../gql/queries'

export default function NetworkIntro({ job, advanceStage }) {
  const { data } = useQuery(QUERY_TRACKED_JOB_CONTACTS, {
    variables: { input: { jobId: job.id } },
  })

  return (
    <StyledWrapper>
      <StyledContent>
        Like it or not, <EmphasizedText>networking</EmphasizedText> is usually
        the best use of your time while you're job hunting.
      </StyledContent>

      {data && data.trackedJobContacts.length ? (
        <>
          <div>
            And look at you, with a contact already created! You can skip ahead
            to the invitation to chat.
          </div>

          <ButtonWrapper>
            <Button onPress={() => advanceStage('NetworkReachOut')}>
              Reach Out
            </Button>
          </ButtonWrapper>
        </>
      ) : (
        <>
          <div>
            Do you already know (or know of) someone at this company? If you're
            not sure, check your alumni database and LinkedIn for shared
            connections.
          </div>

          <ButtonWrapper>
            <Button margin="right" onPress={() => advanceStage('NetworkCold')}>
              No - No Connections
            </Button>
            <Button onPress={() => advanceStage('NetworkAddContact')}>
              Yes - Got Someone In Mind
            </Button>
          </ButtonWrapper>
        </>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
