import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'

export default function NetworkReachOut({ job, advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        Your next step is to <EmphasizedText>reach out</EmphasizedText> to this
        person with a friendly, casual invitation to chat. Your goal is just to
        build a relationship - <i>not</i> to ask for a job.
      </StyledContent>

      <StyledContent>
        Don't overthink it. Here's an example message:
      </StyledContent>

      <StyledQuote>
        <i>
          Hello <b>[name]</b>! I'm considering applying for a developer role at{' '}
          <b>[company]</b>. Would you be up for a quick chat about what it's
          like to work there? Good times for me are{' '}
          <b>[three date/time options]</b>, but I'm flexible. Thanks in advance!
        </i>
      </StyledQuote>

      <StyledLastContent>
        In the next step, you'll generate some questions to prepare for this
        chat.
      </StyledLastContent>

      <StyledButtonWrapper>
        <Button onPress={() => advanceStage('NetworkQuestions')}>
          Message Sent
        </Button>
      </StyledButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`

const StyledLastContent = styled.div`
  margin-top: 12px;
`

const StyledQuote = styled.div`
  border-left: 3px solid #777;
  padding: 12px;
  background: #eee;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
`
