import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function RoundThreeThankYouTechScreen({ advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        You survived the interview!
        <span
          style={{ display: 'inline-block', marginLeft: 5 }}
          role="img"
          aria-label="clap-emoji"
        >
          👏
        </span>
      </StyledContent>

      <StyledContent>
        No matter how you think it went, this is an important milestone. You
        gained valuable practice that will serve you well going forward.
      </StyledContent>

      <div>
        Send a <EmphasizedText>thank you note</EmphasizedText> to the
        interviewer(s) for their time, then wait for their decision.
      </div>

      <ButtonWrapper>
        <Button
          margin="right"
          onPress={() => advanceStage('RoundThreeResults')}
        >
          Note Sent
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
