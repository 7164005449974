import React from 'react'
import GoalTitle from '../GoalTitle'

export default function ScopeProject() {
  return (
    <div>
      <GoalTitle title="Scope your project" icon="looks_two" />

      <p>
        One of the best ways to learn a new skill is to pick a small,
        well-defined project to build with that new technology.
      </p>

      <p>
        <b>What will you build?</b>
      </p>

      <p>
        Shoot for something you can complete in two or three weeks. It doesn't
        need to be perfect. The goal is just to quickly learn some of the
        fundamentals of your new skill.
      </p>

      <p>
        Once you complete a small project, you can always add more features or
        start a new, more ambitious idea. Small or large, each project can be
        included in your portfolio and is a great talking point with an
        interviewer.
      </p>
    </div>
  )
}
