import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

export default function FadeIn({ children }) {
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 0 },
        duration: 1.5,
      },
    },
    closed: {
      y: 10,
      opacity: 0,
      transition: {
        duration: 1,
      },
    },
  }

  return <StyledMotion variants={variants}>{children}</StyledMotion>
}

const StyledMotion = styled(motion.div)`
  margin-top: 12px;
`
