import React, { Component } from 'react'
import styled from 'styled-components'

export default class Placeholder extends Component {
  static propTypes = {}

  render() {
    return (
      <div>
        <StyledTitle>
          Placeholder{' '}
          <span role="img" aria-label="emoji">
            🤷‍♀️
          </span>
        </StyledTitle>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet.
      </div>
    )
  }
}

const StyledTitle = styled.div`
  font-family: 'Dosis';
  color: #777;
  text-transform: uppercase;
  margin-bottom: 12px;
`
