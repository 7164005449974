import React from 'react'
import GoalTitle from '../GoalTitle'

export default function FindVenue() {
  return (
    <div>
      <GoalTitle title="Find your venue" icon="looks_two" />

      <p>
        A great place to give your first talk is at a local{' '}
        <a href="https://meetup.com">Meetup</a> group.
      </p>

      <p>
        Organizers are always looking to schedule lightning (5-10 min) or full
        length (20-45 min) talks. Once you've chosen your talk topic, search for
        relevant Meetups to present at.
      </p>

      <p>
        <b>Reach out to the organizers before you're ready to present.</b> You
        can let them know you're preparing a talk on a particular subject and
        ask if it would be a good fit for one of their upcoming events. This is
        a great opportunity to get some feedback about what their members are
        interested in learning about.
      </p>
    </div>
  )
}
