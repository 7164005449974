import React from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useHistory } from 'react-router-dom'
import { QUERY_USER_SNAPSHOT } from '../../gql/queries'
import Login from '../Login'
import Header from './Header'
import Progress from './Progress'
import MemberList from './MemberList'
import CreateSuggestedJob from './CreateSuggestedJob'
import SuggestedJobsTable from './SuggestedJobsTable'
import 'react-tabs/style/react-tabs.css'

const SUPERADMIN_WHITELIST = [
  'adam@eventual.dev',
  'andrew@eventual.dev',
  'marc@eventual.dev',
]

export default function Snapshot() {
  const signedIn = useSelector(state => !!state.auth.userId)
  const email = useSelector(state => state.auth.email)
  const role = useSelector(state => state.auth.role)
  const history = useHistory()
  const { data } = useQuery(QUERY_USER_SNAPSHOT)

  if (!signedIn) return <Login />
  if (role !== 'staff' && !SUPERADMIN_WHITELIST.includes(email)) {
    history.push('/')
  }

  return (
    <>
      <Header />
      <StyledWrapper>
        <h1>Turing School Snapshot</h1>
        {/*loading && <div>~ Loading ~</div>*/}
        {/*error && <div>Something went wrong.</div>*/}

        <Tabs>
          <TabList>
            <Tab>Student Progress</Tab>
            <Tab disabled>Suggested Jobs</Tab>
            <Tab>Members</Tab>
          </TabList>

          <TabPanel>
            <Progress />
          </TabPanel>

          <TabPanel>
            <CreateSuggestedJob />
            <SuggestedJobsTable />
          </TabPanel>

          <TabPanel>
            <MemberList members={data && data.userSnapshot} />
          </TabPanel>
        </Tabs>
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  margin: 0 24px 12px;

  .react-tabs__tab--selected {
    background: #f9f9f9;
  }
`
