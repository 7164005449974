import React from 'react'
import GoalTitle from '../GoalTitle'

export default function BrainstormContent() {
  return (
    <div>
      <GoalTitle title="Brainstorm content" icon="looks_two" />

      <p>What will you write about? A few ideas:</p>

      <ul>
        <li>
          <b>Your personal story</b>
          <br />
          <i>
            e.g., what drew you to programming, how a past career or hobby helps
            you think about software.
          </i>
        </li>
        <li>
          <b>A learning journal</b>
          <br />
          <i>
            e.g., the hurdles you overcome as you learn a new language or tool.
          </i>
        </li>
        <li>
          <b>Project reviews</b>
          <br />
          <i>e.g., a walkthrough of one of your portfolio projects.</i>
        </li>
      </ul>

      <p>
        Spend a few minutes brainstorming content ideas. More food for thought:
        Do you want this blog to have a theme? What type of content will your
        ideal employer want to see?
      </p>
    </div>
  )
}
