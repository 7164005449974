import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function RoundThreeInterviewType({ advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        Wow, <EmphasizedText>round three</EmphasizedText>!
        <span
          style={{ display: 'inline-block', marginLeft: 3 }}
          aria-label="tada-emoji"
          role="img"
        >
          🎉
        </span>
      </StyledContent>

      <StyledContent>
        This company definitely sees something special in you if they're still
        putting you through the paces.
      </StyledContent>

      <StyledContent>What's next?</StyledContent>

      <ButtonWrapper>
        <Button
          margin="right"
          onPress={() => advanceStage('RoundThreeInterviewPhoneScreen')}
        >
          Phone Screen
        </Button>
        <Button onPress={() => advanceStage('RoundThreeInterviewTechScreen')}>
          Technical Screen
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
