import React from 'react'
import GoalTitle from '../GoalTitle'

export default function BrainstormTalkContent() {
  return (
    <div>
      <GoalTitle title="Brainstorm content" icon="looks_one" />
      <p>What could you give a short talk about?</p>
      <p>
        Don't put too much pressure on yourself - it doesn't have to be
        groundbreaking material. A few ideas:
      </p>
      <ul>
        <li>Convert one of your blog posts into a talk</li>
        <li>
          Highlight the new features in a language or framework you're using
        </li>
        <li>Give a quick walkthrough of a programming pattern</li>
        <li>Compare and contrast testing methodologies</li>
        <li>
          War stories: something that went wrong and how your team fixed it
        </li>
        <li>Look through past talks given at local Meetups for inspiration</li>
      </ul>
    </div>
  )
}
