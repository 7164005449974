import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import ButtonWrapper from '../tracker/ButtonWrapper'

export default function NetworkContactOnboarding({ job, advanceStage }) {
  return (
    <StyledWrapper>
      <StyledContent>
        Great! You've added a contact for this job. You can always find and
        update their information within the contacts tab (
        <i
          className="material-icons"
          style={{ fontSize: '18px', display: 'inline-block' }}
        >
          contacts
        </i>
        ) on the left.
      </StyledContent>

      <div>
        Explore the contacts tab (and any others of interest), then move on to
        craft a message to chat with this person.
      </div>

      <ButtonWrapper>
        <Button onPress={() => advanceStage('NetworkReachOut')}>
          Reach Out
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
