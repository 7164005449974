import React from 'react'
import GoalTitle from '../GoalTitle'

export default function FinishingTouches() {
  return (
    <div>
      <GoalTitle title="Add the finishing touches" icon="looks_6" />

      <p>As your draft nears completion, consider some final questions:</p>
      <ul>
        <li>
          Do your diagrams or visuals make your topic easier to understand? Are
          you missing any important ones?
        </li>
        <li>
          How does the post preview look when shared on social media or a chat
          application? Can you add some metadata to improve that?
        </li>
        <li>Who's feedback on this draft would you value?</li>
      </ul>

      <p>
        When you're satisfied, hit the publish button and share with your
        networks. Congratulations on writing your first technical blog post!
      </p>
    </div>
  )
}
