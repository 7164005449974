import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'

export default class Tailor extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Keep it to one page" icon="📄" />

        <p>
          A simple rule: keep your resume to one page. Even at one page, a
          hiring manager often may not read every word, so keep your content
          focused and to the point.
        </p>
      </div>
    )
  }
}
