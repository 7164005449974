import React from 'react'
import GoalTitle from '../GoalTitle'

export default function ScheduleWriting() {
  return (
    <div>
      <GoalTitle title="Schedule writing time" icon="looks_3" />

      <p>When will you write?</p>

      <p>
        If you're serious about this new hobby, block off some reoccurring time
        on the calendar for it. This will go a long way toward building a new
        habit.
      </p>
    </div>
  )
}
