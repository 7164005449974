import React from 'react'
import styled from 'styled-components'
import NewJobForm from './NewJobForm'
import Modal from '../../shared/Modal'
import Button from '../../shared/Button'

export default function AddJobModal({ showModal, closeModal }) {
  return (
    <Modal showModal={showModal}>
      <StyledBody>
        <StyledHeader>
          <StyledTitle>track a job</StyledTitle>
          <StyledButton onPress={closeModal} size="icon">
            <i className="material-icons">close</i>
          </StyledButton>
        </StyledHeader>
        <NewJobForm isModal closeModal={closeModal} />
      </StyledBody>
    </Modal>
  )
}

const StyledBody = styled.div`
  padding: 12px;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledTitle = styled.div`
  font-family: 'Dosis';
  font-weight: 600;
  display: inline-block;
  font-size: 22px;
`

const StyledButton = styled(Button)`
  display: inline-block;
  text-align: right;
`
