import Welcome from './Welcome'
import Breathe from './Breathe'
import Intro from './Intro'
import GoalIntro from './GoalIntro'
import TrackerIntro from './TrackerIntro'
import Learn from './Learn'
import Smile from './Smile'
import YourStory from './YourStory'
import Final from './Final'

export default {
  Welcome,
  Breathe,
  Intro,
  GoalIntro,
  TrackerIntro,
  Learn,
  Smile,
  YourStory,
  Final,
}

// The order that cards are displayed:
export const stepData = [
  { componentType: 'Welcome', complete: false },
  { componentType: 'Breathe', complete: false },
  { componentType: 'Intro', complete: false },
  { componentType: 'GoalIntro', complete: false },
  { componentType: 'TrackerIntro', complete: false },
  { componentType: 'Learn', complete: false },
  { componentType: 'Smile', complete: false },
  { componentType: 'YourStory', complete: false },
  { componentType: 'Final', complete: false },
]
