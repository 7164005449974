import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default class SubstepTitle extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
  }

  render() {
    const { title, icon } = this.props

    return (
      <StyledTitle>
        {`${title} `}
        {icon && (
          <span role="img" aria-label="substep-icon">
            {icon}
          </span>
        )}
      </StyledTitle>
    )
  }
}

const StyledTitle = styled.div`
  font-family: 'Raleway', sans serif;
  font-weight: 200;
  color: #777;
  text-transform: lowercase;
  margin-bottom: 12px;
  font-size: 28px;
`
