import React, { Component } from 'react'
import SubstepTitle from '../SubstepTitle'
import ListItem from '../ListItem'

export default class Speak extends Component {
  render() {
    return (
      <div>
        <SubstepTitle title="Give a talk" icon="🗣" />

        <p>
          A great way to get on the radar is to give a talk at a meetup or
          conference.
        </p>

        <p>
          Full-length talks range between 20-50 minutes, but a great way to get
          started is to give a lightning talk (5-10 minutes).
        </p>

        <p>Some ideas for talks:</p>

        <ul>
          <ListItem text="Convert one of your blog posts into a talk" />
          <ListItem text="Give an introduction to the specialization you chose" />
          <ListItem text="Highlight the new features in a language or framework" />
        </ul>
      </div>
    )
  }
}
