import React from 'react'
import styled from 'styled-components'

export default function DeclinedOffer() {
  return <StyledWrapper>Declined offer</StyledWrapper>
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
