import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { CHANGE_PASSWORD } from '../gql/mutations'
import Button from './shared/Button'
import Header from './orgs/Header'

export default function Password() {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [changePassword, { data, /* loading, */ error }] = useMutation(
    CHANGE_PASSWORD
  )

  function resetForm() {
    setOldPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  function handleClick() {
    const input = {
      oldPassword,
      newPassword,
    }
    changePassword({ variables: { input } })
    resetForm()
  }

  return (
    <>
      <Header />

      <StyledWrapper>
        <StyledFormWrapper>
          <StyledTitle>Change Password</StyledTitle>
          <StyledInput
            value={oldPassword}
            onChange={e => setOldPassword(e.target.value)}
            placeholder="current password"
            type="password"
          />
          <StyledInput
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            placeholder="new password"
            type="password"
          />
          <StyledInput
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            placeholder="confirm new password"
            type="password"
          />
          <StyledSubmitButton
            disabled={
              newPassword === '' ||
              oldPassword === '' ||
              newPassword !== confirmPassword
            }
            onPress={handleClick}
          >
            Change Password
          </StyledSubmitButton>
          {error && (
            <StyledError>
              Whoops! Something went wrong.
              <br />
              Double-check your current password.
            </StyledError>
          )}
          {data && (
            <StyledSuccess>Success! Password has been upated.</StyledSuccess>
          )}
        </StyledFormWrapper>
        <StyledLink to="/">
          <span
            style={{ fontSize: 20 }}
            role="img"
            aria-label="left-arrow-emoji"
          >
            ←
          </span>{' '}
          Back to Eventual
        </StyledLink>
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15vh auto;
`

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #f8f8f8;
  box-shadow: 0 3px 4px #ccc;
  border-radius: 6px;
  width: 80%;
  max-width: 440px;
`

const StyledTitle = styled.div`
  font-size: 28px;
  margin: 12px 0;
`

const StyledInput = styled.input`
  display: block;
  margin-bottom: 12px;
`

const StyledSubmitButton = styled(Button)`
  margin-bottom: 0;
`

const StyledSuccess = styled.div`
  color: rgb(50, 168, 82);
  margin-top: 12px;
`

const StyledError = styled.div`
  color: rgb(229, 96, 96);
  margin-top: 12px;
`

const StyledLink = styled(Link)`
  margin-top: 12px;
`
