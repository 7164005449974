import React from 'react'
import GoalTitle from '../GoalTitle'

export default function PracticeTalk() {
  return (
    <div>
      <GoalTitle title="Practice" icon="looks_4" />

      <p>
        It's fine to mumble through a few practice runs. That's part of the
        process.
      </p>

      <p>
        If possible, though, try to give at least one dry run to a friend or
        colleague. Some things just aren't obvious until you they leave your
        head and make contact with the real world.
      </p>
    </div>
  )
}
