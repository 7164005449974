import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_USER_JOB } from '../../../gql/mutations'
import Button from '../../shared/Button'

export default function Notes({
  id: jobId,
  notes: currentNotes,
  excited: currentExcited,
}) {
  const [notes, setNotes] = useState(currentNotes || '')
  const [excited, setExcited] = useState(currentExcited || '')
  const [saveSuccess, toggleSaveSuccess] = useState(false)

  const [updateUserJob, { loading: saving, error: savingError }] = useMutation(
    UPDATE_USER_JOB,
    {
      refetchQueries: ['QUERY_TRACKED_JOBS'],
      onCompleted: () => toggleSaveSuccess(true),
    }
  )

  function handleUpdateUserJob() {
    if (saveSuccess) toggleSaveSuccess(false)
    if (saving) return
    updateUserJob({ variables: { input: { jobId, notes, excited } } })
  }

  return (
    <StyledWrapper>
      {savingError && (
        <StyledError>Whoops! {savingError.toString()}</StyledError>
      )}

      {saveSuccess && (
        <StyledSuccess>
          Your changes have been saved.
          <span
            style={{ display: 'inline-block', marginLeft: 3 }}
            role="img"
            aria-label="check-emoji"
          >
            ✔️
          </span>
        </StyledSuccess>
      )}

      <div>Why you're excited about this job:</div>
      <StyledTextArea
        placeholder="e.g., product, team, industry, personal mission"
        onChange={e => setExcited(e.target.value)}
        value={excited}
      >
        {excited}
      </StyledTextArea>

      <div>Your notes:</div>
      <StyledTextArea
        placeholder="e.g., tasks, names, dates to keep track of"
        onChange={e => setNotes(e.target.value)}
        value={notes}
      >
        {notes}
      </StyledTextArea>
      <Button
        disabled={notes === currentNotes && excited === currentExcited}
        onPress={handleUpdateUserJob}
      >
        Save
      </Button>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTextArea = styled.textarea`
  display: block;
  margin: 4px 0 12px;
`

const StyledError = styled.div`
  color: rgb(229, 96, 96);
  margin-top: 12px;
`

const StyledSuccess = styled.div`
  color: #5e715e;
  background: #d7f6d6
  padding: 8px 12px;
  border-radius: 2;
  border: 1px solid #5e715e;
  margin-bottom: 12px;
  font-weight: bold;
  text-align: center;
`
