import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks'
import Select from 'react-select'
import {
  QUERY_ORG_GROUPS,
  QUERY_USER_ORG_GROUPS,
  QUERY_USERS,
} from '../../gql/queries'
import {
  ADD_USER_TO_ORG_GROUP,
  CREATE_ORG_GROUP,
  EDIT_ORG_GROUP,
  REMOVE_USER_FROM_ORG_GROUP,
} from '../../gql/mutations'
import Button from '../shared/Button'

export default function AdminManageGroups() {
  const [newGroup, setNewGroup] = useState('')
  const [editedName, setEditedName] = useState('')
  const [user, setUser] = useState({ label: '(None)', value: '' })
  const [group, setGroup] = useState({ label: '(None)', value: '' })
  const [userOrgGroups, setUserOrgGroups] = useState([])
  const [createOrgGroup] = useMutation(CREATE_ORG_GROUP, {
    refetchQueries: ['QUERY_ORG_GROUPS'],
  })
  const [editOrgGroup] = useMutation(EDIT_ORG_GROUP, {
    refetchQueries: ['QUERY_ORG_GROUPS'],
  })
  const [addUserToOrgGroup] = useMutation(ADD_USER_TO_ORG_GROUP, {
    refetchQueries: [
      {
        query: QUERY_USER_ORG_GROUPS,
        variables: { input: { userId: user.value, orgId: '1' } },
      },
    ],
  })
  const [removeUserFromOrgGroup] = useMutation(REMOVE_USER_FROM_ORG_GROUP, {
    refetchQueries: [
      {
        query: QUERY_USER_ORG_GROUPS,
        variables: { input: { userId: user.value, orgId: '1' } },
      },
    ],
    onCompleted: data =>
      setUserOrgGroups(
        userOrgGroups.filter(
          g => g.id !== data.removeUserFromOrgGroup.toString()
        )
      ),
  })
  const { data: userData } = useQuery(QUERY_USERS)
  const { data: groupData } = useQuery(QUERY_ORG_GROUPS)

  const client = useApolloClient()

  async function fetchGroups() {
    const response = await client.query({
      query: QUERY_USER_ORG_GROUPS,
      variables: { input: { userId: user.value, orgId: '1' } },
    })
    setUserOrgGroups(response.data.userOrgGroups || [])
  }

  useEffect(() => {
    if (user.value) {
      fetchGroups()
    } else {
      setUserOrgGroups([])
    }
  }, [user.value])

  let userOptions = []
  if (userData && userData.users) {
    userOptions = userData.users.map(user => ({
      label: user.email,
      value: user.id,
    }))
  }
  userOptions.unshift({ value: '', label: '(None)' })

  let groupOptions = []
  if (groupData && groupData.orgGroups) {
    groupOptions = groupData.orgGroups.map(group => ({
      label: group.name,
      value: group.id,
    }))
  }
  groupOptions.unshift({ value: '', label: '(None)' })

  function handleCreateGroup() {
    createOrgGroup({ variables: { input: { name: newGroup } } })
    setNewGroup('')
  }

  function handleEditGroup() {
    editOrgGroup({
      variables: {
        input: { name: editedName, orgGroupId: group.value, orgId: '1' },
      },
    })
    setEditedName('')
    setGroup({ label: '(None)', value: '' })
    setUser({ label: '(None)', value: '' })
  }

  function handleAddUserToGroup() {
    addUserToOrgGroup({
      variables: {
        input: { userId: user.value, orgGroupId: group.value, orgId: '1' },
      },
    })
    setEditedName('')
    setUser({ label: '(None)', value: '' })
    setGroup({ label: '(None)', value: '' })
  }

  function handleRemoveUserFromGroup(orgGroupId) {
    removeUserFromOrgGroup({
      variables: {
        input: { userId: user.value, orgGroupId, orgId: '1' },
      },
    })
  }

  return (
    <StyledWrapper>
      <StyledForm>
        <StyledInput
          value={newGroup}
          onChange={e => setNewGroup(e.target.value)}
          placeholder="Group Name, e.g., 1909 BE"
          type="text"
        />
        <StyledSubmitButton disabled={!newGroup} onPress={handleCreateGroup}>
          Create Group
        </StyledSubmitButton>
      </StyledForm>

      <StyledForm>
        <StyledSelectContainer>
          <Select
            placeholder="group"
            options={groupOptions}
            value={group}
            onChange={group => setGroup(group)}
          />

          <StyledTitle>Add or remove a user from a group:</StyledTitle>
          <StyledSelect
            placeholder="user"
            options={userOptions}
            value={user}
            onChange={user => setUser(user)}
          />

          {userOrgGroups.map(group => (
            <div key={group.id}>
              {group.name}
              <StyledRemoveGroupButton
                style={{ zIndex: 0 }}
                size="icon"
                color="red"
                onPress={() => handleRemoveUserFromGroup(group.id)}
              >
                <i className="material-icons">clear</i>
              </StyledRemoveGroupButton>
            </div>
          ))}
        </StyledSelectContainer>
        <StyledSubmitButton
          disabled={!group.value || !user.value}
          onPress={handleAddUserToGroup}
        >
          Add User To Group
        </StyledSubmitButton>

        <StyledTitle>Edit the group:</StyledTitle>
        <StyledInput
          value={editedName}
          onChange={e => setEditedName(e.target.value)}
          placeholder="Updated Name, e.g., 1909 FE"
          type="text"
        />
        <StyledSubmitButton
          disabled={!editedName || !group.value}
          onPress={handleEditGroup}
        >
          Edit Group
        </StyledSubmitButton>
      </StyledForm>

      <StyledTable className="eventual-table">
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
          </tr>
        </thead>
        <tbody>
          {groupData &&
            groupData.orgGroups
              .sort((a, b) => a.name > b.name)
              .map(group => {
                return (
                  <tr key={group.id}>
                    <td>{group.id}</td>
                    <td>{group.name}</td>
                  </tr>
                )
              })}
        </tbody>
      </StyledTable>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTitle = styled.div`
  font-weight: bold;
  margin: 12px 0;
`

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  background-color: white;
  padding: 12px;
  border-radius: 6px;
  border: 3px dashed;
  max-width: 440px;
`

const StyledInput = styled.input`
  display: block;
`

const StyledSelectContainer = styled.div`
  z-index: 999;
`

const StyledSelect = styled(Select)`
  display: block;
  margin-top: 12px;
`

const StyledSubmitButton = styled(Button)`
  margin-top: 12px;
`

const StyledTable = styled.table`
  margin-top: 12px;
`

const StyledRemoveGroupButton = styled(Button)`
  transform: scale(0.55);
`
