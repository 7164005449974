import React from 'react'
import styled from 'styled-components'
import Button from '../../shared/Button'
import EmphasizedText from '../../shared/EmphasizedText'
import ButtonWrapper from '../tracker/ButtonWrapper'
import Resources from './Resources'

export default function CodeChallenge({ advanceStage }) {
  return (
    <StyledWrapper>
      <div>
        A <EmphasizedText>code challenge</EmphasizedText> serves a few purposes.
        Your interviewers want to know if you:
        <ol>
          <li>have some basic competencies,</li>
          <li>can write organized, maintainable code, and</li>
          <li>share their values, like the importance of testing.</li>
        </ol>
      </div>

      <div>Earn bonus points by:</div>
      <ul>
        <li>
          <b>writing a robust README.</b> Include a project description,
          instructions for setting up the local developer environment, and
          anything you want to highlight in the codebase.
        </li>
        <li>
          <b>testing your software.</b> It gives you and your interviewer
          confidence that the code works and is a form of documentation in its
          own right.
        </li>
      </ul>

      <StyledContent>
        After you submit the code challenge, wait to hear whether the team wants
        to schedule a follow-up interview.
      </StyledContent>

      <Resources>
        <li>
          <a
            href="https://www.fullstackinterviewing.com/2018/02/02/the-ultimate-guide-to-kicking-ass-on-take-home-coding-challenges.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Ultimate Guide to Kicking Ass on Take-home Coding Challenges
          </a>
        </li>
      </Resources>

      <ButtonWrapper>
        <Button margin="right" onPress={() => advanceStage('Retrospective')}>
          Rejected
        </Button>
        <Button onPress={() => advanceStage('RoundOneInterviewType')}>
          Interview Scheduled
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  margin-bottom: 12px;
`
