import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import styled from 'styled-components'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { motion, AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import Tooltip from 'react-tooltip'
import { QUERY_STEPS } from '../gql/queries'
import { COMPLETE_STEP, RESET_STEPS } from '../gql/mutations'
import Header from './Header'
import JobTracker from './jobs/tracker/JobTracker'
import { incrementFlowStep, setActiveFlowStep } from '../store/ui/actions'
import Components, { stepData } from './flowsteps/'
import FlowStepCard from './flowsteps/FlowStepCard'
import Final from './flowsteps/Final'
import Button from './shared/Button'
import LinkButton from './shared/LinkButton'

const components = { ...Components }

const trackerVariants = {
  open: {
    opacity: 1,
    y: 0,
    height: 'auto',
    transition: {
      y: { stiffness: 1000, velocity: -100 },
      delay: 1,
      duration: 1.5,
    },
  },
  closed: {
    opacity: 0,
    y: 50,
    height: 0,
    transition: {
      y: { stiffness: 1000, velocity: -100, delay: 1, duration: 1 },
      opacity: { duration: 0.5 },
    },
    transitionEnd: { display: 'none' },
  },
}

const stepVariants = {
  open: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.5,
    },
    transitionEnd: { display: 'none' },
  },
  closed: {
    opacity: 1,
    height: 'auto',
    transition: {
      delay: 0.8,
      duration: 2,
    },
  },
}

const stepContentVariants = {
  enter: { opacity: 0, scale: 0.95, transition: { duration: 1 } },
  center: { opacity: 1, scale: 1, transition: { duration: 1, delay: 0.3 } },
  exit: { opacity: 0, scale: 1.05, transition: { duration: 0.7 } },
}

function cloneStepData() {
  return JSON.parse(JSON.stringify(stepData))
}

export default function FlowCore() {
  const history = useHistory()
  const activeFlowStep = useSelector(state => state.ui.activeFlowStep)
  const dispatch = useDispatch()
  const store = useStore()
  const userId = store.getState().auth.userId
  const [steps, updateStepData] = useState(cloneStepData())
  const { data /*, loading,  error*/ } = useQuery(QUERY_STEPS, {
    variables: { userId },
  })
  const [completeStep] = useMutation(COMPLETE_STEP, {
    refetchQueries: ['QUERY_STEPS'],
  })
  const [resetSteps] = useMutation(RESET_STEPS, {
    refetchQueries: ['QUERY_STEPS'],
    onCompleted: resetStepsUI,
  })

  useEffect(() => {
    if (data) {
      updateCompletions(steps, data.completedSteps)
      viewLatestStep()
    }
  }, [data])

  if (!data) return <></>

  function renderSubstepComponent(componentType) {
    const Component = components[componentType]
    Tooltip.rebuild()
    if (!Component) return <Final />
    return <Component advanceStep={advanceStep} stepKey={componentType} />
  }

  function updateCompletions(steps, completedSteps) {
    const completedCodes = completedSteps.map(cs => cs.code)
    const newSteps = steps.slice()

    // Mark each substep as complete
    newSteps.forEach((step, stepIndex) => {
      if (completedCodes.includes(step.componentType)) {
        newSteps[stepIndex].complete = true
      }
    })

    updateStepData(newSteps)
  }

  function resetStepsUI() {
    updateStepData(cloneStepData())
    viewLatestStep()
  }

  function advanceStep(stepName) {
    if (stepName) completeStep({ variables: { input: { code: stepName } } })
    dispatch(incrementFlowStep())
  }

  function setActive(activeFlowStep) {
    dispatch(setActiveFlowStep(activeFlowStep))
  }

  function viewLatestStep() {
    let stepIndex = steps.findIndex(step => !step.complete)
    // If steps are complete, set the last step & substep as active
    if (stepIndex === -1) stepIndex = steps.length - 1
    setActive(stepIndex)
  }

  const step = steps[activeFlowStep]

  return (
    <>
      <motion.div
        initial={false}
        animate={history.location.pathname === '/tracker' ? 'open' : 'closed'}
      >
        <Header viewLatestStep={viewLatestStep} />

        <StyledBody>
          <motion.div variants={stepVariants}>
            <AnimatePresence>
              <FlowStepCard>
                <motion.div
                  key={`active-step-${step.componentType}`}
                  variants={stepContentVariants}
                  initial="enter"
                  enter="enter"
                  animate="center"
                  exit="exit"
                >
                  <Tooltip
                    id="flowstep-nav-tabs"
                    className="react-tooltip-overrides"
                    place="right"
                    effect="solid"
                    type="light"
                    border
                  />
                  {renderSubstepComponent(step.componentType)}

                  {step.componentType === 'Final' && (
                    <div style={{ textAlign: 'center' }}>
                      <LinkButton onClick={() => resetSteps()}>
                        Reset
                      </LinkButton>
                    </div>
                  )}
                </motion.div>
              </FlowStepCard>
            </AnimatePresence>
          </motion.div>

          <motion.div variants={trackerVariants}>
            <JobTracker />
          </motion.div>
        </StyledBody>
      </motion.div>

      <StyledFeedbackButton
        onPress={() =>
          window.open('https://forms.gle/v5LY22kxh2pbRUAw9', '_blank')
        }
        size="icon"
      >
        <i className="material-icons">feedback</i>
      </StyledFeedbackButton>
    </>
  )
}

const StyledBody = styled.div`
  padding-top: 24px;
`

const StyledFeedbackButton = styled(Button)`
  position: fixed;
  right: 12px;
  bottom: 12px;
`
